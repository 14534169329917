import React from "react";

const GPPGoodOutlinedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9 12.75L11.25 15L15 9.74999M12 2.71399C9.73574 4.86422 6.72026 6.04357 3.598 5.99999C3.20084 7.20997 2.99898 8.47549 3 9.74899C3 15.341 6.824 20.039 12 21.372C17.176 20.04 21 15.342 21 9.74999C21 8.43999 20.79 7.17899 20.402 5.99899H20.25C17.054 5.99899 14.15 4.75099 12 2.71399Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GPPGoodOutlinedIcon;
