import React from "react";

import { Modal } from "../../../../..";

function AssetViewer({ isOpen, onCloseModal, title, testHTML }) {
  const handleCloseModal = () => {
    onCloseModal();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal} title={title}>
      <div>
        {testHTML && (
          <iframe
            className="bg-white border-none rounded-b-small"
            title={title}
            srcDoc={testHTML}
            height="500px"
            width="100%"
          ></iframe>
        )}
      </div>
    </Modal>
  );
}

export default AssetViewer;
