import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { Descope, useSession, useUser } from "@descope/react-sdk";

import { LogoItem } from "../../components";

import "./login.css";

function Login() {
  // Loading
  const [isLoading, setIsLoading] = useState(true);

  // Roles
  const [isSuperadmin, setIsSuperadmin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isManager, setIsManager] = useState(false);

  const { isAuthenticated, isSessionLoading } = useSession();
  const { user, isUserLoading } = useUser();

  useEffect(() => {
    const updateComponent = async () => {
      const { roleNames, userTenants } = user;

      let tenantRoles = [];

      if (userTenants && userTenants.length > 0 && userTenants[0].roleNames) {
        tenantRoles = userTenants[0].roleNames;
      }

      if (
        roleNames.includes("Superadmin") ||
        tenantRoles.includes("Superadmin")
      ) {
        setIsSuperadmin(true);
      }

      if (roleNames.includes("Admin") || tenantRoles.includes("Admin")) {
        setIsAdmin(true);
      }

      if (roleNames.includes("Manager") || tenantRoles.includes("Manager")) {
        setIsManager(true);
      }
    };

    if (isAuthenticated && !isSessionLoading && !isUserLoading) {
      setIsLoading(true);

      updateComponent().finally(() => {
        setIsLoading(false);
      });
    }
  }, [isAuthenticated, isUserLoading, isSessionLoading, user]);

  if (!isLoading && isAuthenticated) {
    return (
      <Navigate
        to={
          isSuperadmin || isAdmin
            ? "/insights/organization/"
            : isManager
              ? "/insights/your-reports/"
              : // : "/dashboard/insights/")
                "/dashboard/trainings/"
        }
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>Dune Security | User Adaptive Risk Management</title>
        <meta
          name="description"
          content="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk employees against social engineering, ensuring personalized, effective defense strategies."
        />
      </Helmet>
      <div className="relative flex items-center justify-center h-screen login-box-main">
        <div
          className="flex flex-col justify-between w-full max-w-[450px] rounded-[32px] py-3.75 px-1.875 min-h-[550px]"
          style={{
            border: "1.401px solid rgba(194, 191, 184, 0.35)",
            background: "rgba(12, 12, 11, 0.85)",
            boxShadow:
              "0px 0px 61.664px 0px rgba(2, 2, 2, 0.25) inset, 16.818px 0px 33.635px 0px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div>
            <div className="flex justify-center">
              <LogoItem height="40px" />
            </div>
            <div className="mt-0.625 text-center">
              <p className="body">
                User Adaptive Risk Management,
                <br />
                <span className="dune-italic">powered by AI</span>
              </p>
            </div>
            <div className="mt-3.125 min-h-[227.09px] h-full">
              <Descope
                flowId="sign-in"
                onSuccess={(e) => console.log(e)}
                onError={(e) => console.log("Could not log in!")}
                redirectUrl={process.env.REACT_APP_REDIRECT_URL}
              />
            </div>
          </div>
          <div className="mt-1.25 text-center">
            <p className="detailed text-gray">
              Copyright © {new Date().getFullYear()} Dune Security
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
