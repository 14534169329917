import React from "react";

import { ButtonOutlinedGray } from "../buttons";

import { SearchIcon } from "../../../utils/icons";

const TextField = ({ value, onChangeValue, placeholder = "", isSearch }) => {
  const handleChangeValue = (event) => {
    if (onChangeValue) {
      onChangeValue(event);
    }
  };

  return (
    <div className="flex max-w-[560px] w-full">
      <input
        type="text"
        className={`py-[10px] px-[8px] w-full description bg-gray-select
            placeholder-white placeholder-opacity-50 placeholder:italic border-border-color
            ${isSearch ? "border-b border-t border-l rounded-tl-small rounded-bl-small" : "border rounded-small"}
            focus:ring-0 focus:outline-none focus:border-green focus:border focus:rounded-small`}
        value={value}
        placeholder={placeholder}
        autoComplete="off"
        onChange={handleChangeValue}
      />
      <ButtonOutlinedGray padding="p-0.5" isEndButton>
        <SearchIcon />
      </ButtonOutlinedGray>
    </div>
  );
};

export default TextField;
