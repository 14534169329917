import React from "react";

import { Card } from "../../..";

function CardProfileUser({ profileData, description }) {
  return (
    <Card>
      <div className="flex flex-row justify-between">
        {profileData && profileData.role && (
          <div>
            <div>
              <p className="card-subheader">Title</p>
            </div>
            <div className="mt-0.25">
              <p className="font-medium body">{profileData.role}</p>
            </div>
          </div>
        )}
        {profileData && profileData.email && (
          <div className={profileData && profileData.role ? "ml-1.25" : ""}>
            <div>
              <p className="card-subheader">Email</p>
            </div>
            <div className="mt-0.25">
              <p className="font-medium body">{profileData.email}</p>
            </div>
          </div>
        )}
      </div>
      {description && (
        <div
          className={
            profileData && (profileData.email || profileData.role) ? "mt-2" : ""
          }
        >
          <p className="body">{description}</p>
        </div>
      )}
    </Card>
  );
}

export default CardProfileUser;
