import React from "react";

const TestingFilledIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M8 16.9999V16.4249C8 15.6916 8.36667 15.1041 9.1 14.6624C9.83333 14.2207 10.8 13.9999 12 13.9999C13.2 13.9999 14.1667 14.2207 14.9 14.6624C15.6333 15.1041 16 15.6916 16 16.4249V16.9999H8ZM12 12.9999C11.45 12.9999 10.9792 12.8041 10.5875 12.4124C10.1958 12.0207 10 11.5499 10 10.9999C10 10.4499 10.1958 9.97907 10.5875 9.5874C10.9792 9.19574 11.45 8.9999 12 8.9999C12.55 8.9999 13.0208 9.19574 13.4125 9.5874C13.8042 9.97907 14 10.4499 14 10.9999C14 11.5499 13.8042 12.0207 13.4125 12.4124C13.0208 12.8041 12.55 12.9999 12 12.9999ZM7 20.1499C5.08333 19.9166 3.60417 19.5249 2.5625 18.9749C1.52083 18.4249 1 17.7666 1 16.9999V6.9999C1 6.0499 1.94583 5.31657 3.8375 4.7999C5.72917 4.28324 8.45 4.0249 12 4.0249C15.55 4.0249 18.2708 4.28324 20.1625 4.7999C22.0542 5.31657 23 6.0499 23 6.9999V16.9999C23 17.7666 22.4792 18.4249 21.4375 18.9749C20.3958 19.5249 18.9167 19.9166 17 20.1499V8.1499C17.85 8.01657 18.6042 7.85407 19.2625 7.6624C19.9208 7.47074 20.3917 7.28324 20.675 7.0999C19.9583 6.81657 18.7333 6.5624 17 6.3374C15.2667 6.1124 13.6 5.9999 12 5.9999C10.4 5.9999 8.73333 6.1124 7 6.3374C5.26667 6.5624 4.04167 6.81657 3.325 7.0999C3.60833 7.2999 4.07917 7.49157 4.7375 7.6749C5.39583 7.85824 6.15 8.01657 7 8.1499V20.1499Z"
        fill="white"
      />
    </svg>
  );
};

export default TestingFilledIcon;
