import React from "react";
import { Link } from "react-router-dom";

import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { WarningAmberOutlined } from "@mui/icons-material";

import "./heads-up.css";

function HeadsUp({ imageURL }) {
  return (
    <Grid container className="dune-grid-container">
      <Grid item xs={12}>
        <Box className="heads-up-box-main">
          <Box
            component="img"
            src="https://d3oo9a669kwmx7.cloudfront.net/dune-logo-green-on-black.png"
            alt="Dune logo"
            height="28px"
            width="28px"
            marginLeft="-4px"
          />
          <Typography
            role="heading"
            aria-level="1"
            component="h1"
            className="heads-up-text-header"
            marginTop="40px"
          >
            Heads up! This was a phishing test.
          </Typography>
          <Typography component="p" marginTop="40px">
            Watch out for these signs so you can protect yourself. To learn
            more, check out your assigned training.
          </Typography>
          <Button
            component={Link}
            variant="outlined"
            className="dune-button-outlined-white heads-up-button"
            fullWidth
            to="/login/"
            aria-label="Go to trainings."
            title="Go to trainings."
          >
            Go to trainings
          </Button>
        </Box>
        {imageURL && (
          <Box
            component="img"
            className="heads-up-image-screenshot"
            src={imageURL}
            alt="Email screenshot"
            marginTop="160px"
          />
        )}
        <Box className="heads-up-box-sign" marginTop="80px">
          <Grid container columnSpacing={2} alignItems="center">
            <Grid item xs={12} md={3.5}>
              <Stack direction="row" alignItems="center">
                <WarningAmberOutlined className="heads-up-icon-warning" />
                <Typography
                  component="p"
                  className="heads-up-text-sign"
                  marginLeft="20px"
                >
                  Impersonation
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={8.5}>
              <Typography component="p">
                Be careful with any email coming from outside your company.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box className="heads-up-box-sign" marginTop="40px">
          <Grid container columnSpacing={2} alignItems="center">
            <Grid item xs={12} md={3.5}>
              <Stack direction="row" alignItems="center">
                <WarningAmberOutlined className="heads-up-icon-warning" />
                <Typography
                  component="p"
                  className="heads-up-text-sign"
                  marginLeft="20px"
                >
                  Domain spoofing
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={8.5}>
              <Typography component="p">
                Don't click on links or attachments unless you know and trust
                the sender.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

export default HeadsUp;
