import React, { useState } from "react";
import {
  DeleteDumpsterIcon,
  DoubleArrowDropdownIcon,
  DropDownArrowIcon,
} from "../../../utils/icons";
import { Link } from "react-router-dom";
import { ButtonOutlinedGreen } from "../../../components";

const ConfigureTestingSchedule = () => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [editConfigureInitialTesting, setEditConfigureInitialTesting] =
    useState(false);
  const [editConfigureOngoingTesting, setEditConfigureOngoingTesting] =
    useState(false);

  const [emailsSentEachWeek, setEmailsSentEachWeek] = useState(8);
  const [initialAssessmentLasts, setInitialAssessmentLasts] = useState(10);
  const [highRiskMaxTestPerQuarter, setHighRiskMaxTestPerQuarter] = useState(8);
  const [highRiskMinTestPerQuarter, setHighRiskMinTestPerQuarter] = useState(8);
  const [mediumRiskMaxTestPerQuarter, setMediumRiskMaxTestPerQuarter] =
    useState(8);
  const [mediumRiskMinTestPerQuarter, setMediumRiskMinTestPerQuarter] =
    useState(8);
  const [selectUserGroupDropdown, setSelectUserGroupDeropdown] =
    useState(false);
  const [selectedGroupMinTest, setSelectedGroupMinTest] = useState();
  const [selectedGroupMaxTest, setSelectedGroupMaxTest] = useState();
  const [
    configureOngoingMaxTest,
    // setConfigureOngoingMaxTest
  ] = useState(8);
  const [
    configureOngoingMinTest,
    // setConfigureOngoingMinTest
  ] = useState(10);

  const toggleSelectUserGroupDropdown = () => {
    setSelectUserGroupDeropdown(!selectUserGroupDropdown);
  };

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  function isPositiveNumberOnly(input) {
    const positiveNumberRegex = /^[1-9]\d*$/;
    return positiveNumberRegex.test(input);
  }

  const handleEmailSentChange = (event) => {
    const inputValue = event.target.value;

    if (isPositiveNumberOnly(inputValue)) {
      setEmailsSentEachWeek(inputValue);
    }
  };

  const handleInitialAssessment = (event) => {
    const inputValue = event.target.value;

    if (isPositiveNumberOnly(inputValue)) {
      setInitialAssessmentLasts(inputValue);
    }
  };

  const handleMedMinTestPerQuarter = (event) => {
    const inputValue = event.target.value;

    if (isPositiveNumberOnly(inputValue)) {
      setMediumRiskMinTestPerQuarter(inputValue);
    }
  };

  const handleMedMaxTestPerQuarter = (event) => {
    const inputValue = event.target.value;

    if (isPositiveNumberOnly(inputValue)) {
      setMediumRiskMaxTestPerQuarter(inputValue);
    }
  };

  const handleHighMinTestPerQuarter = (event) => {
    const inputValue = event.target.value;

    if (isPositiveNumberOnly(inputValue)) {
      setHighRiskMinTestPerQuarter(inputValue);
    }
  };

  const handleHighMaxTestPerQuarter = (event) => {
    const inputValue = event.target.value;

    if (isPositiveNumberOnly(inputValue)) {
      setHighRiskMaxTestPerQuarter(inputValue);
    }
  };

  const handleSelectedGroupMinTest = (event) => {
    const inputValue = event.target.value;

    if (isPositiveNumberOnly(inputValue)) {
      setSelectedGroupMinTest(inputValue);
    }
  };

  const handleSelectedGroupMaxTest = (event) => {
    const inputValue = event.target.value;

    if (isPositiveNumberOnly(inputValue)) {
      setSelectedGroupMaxTest(inputValue);
    }
  };

  return (
    <div className="text-white">
      <div className="text-h2">
        <Link to="/configure/testing/">Testing</Link> {`>`} Configure Testing
        Schedule
      </div>
      <div>
        <div className="flex items-center justify-between">
          <div className="mb-0.5 mt-2 text-h4">Configure Initial testing</div>
          <ButtonOutlinedGreen
            onClick={() => setEditConfigureInitialTesting(true)}
          >
            Modify
          </ButtonOutlinedGreen>
        </div>
        <div className="border bg-gray-background border-gray border-opacity-35 min-h-2 rounded-medium">
          <div className="flex items-center justify-center">
            <div className="w-1/2">
              <div
                className={`flex justify-between items-center pt-3 pl-1 pr-4 ${editConfigureInitialTesting ? "pb-3" : "pb-4"}`}
              >
                <div>
                  <div className="text-h5">
                    Social Engineering tests per week
                  </div>
                  <div className="text-h6 w-14">
                    Number of emails sent to new employees each week
                  </div>
                </div>
                {editConfigureInitialTesting ? (
                  <div className="text-h5">
                    <input
                      type="text"
                      className="bg-transparent border border-gray border-opacity-35 rounded-medium pl-1 py-0.75"
                      value={emailsSentEachWeek}
                      onChange={handleEmailSentChange}
                    />
                  </div>
                ) : (
                  <div className="text-h2">{emailsSentEachWeek}</div>
                )}
              </div>
            </div>
            <div className="w-1/2">
              <div
                className={`flex justify-between items-center pt-3 pl-1 pr-1 ${editConfigureInitialTesting ? "pb-3" : "pb-4"}`}
              >
                <div>
                  <div className="text-h5">Initial Test Duration</div>
                  <div className="text-h6 w-14">
                    Number of weeks the initial assessment lasts
                  </div>
                </div>
                {editConfigureInitialTesting ? (
                  <div className="text-h5">
                    <input
                      type="text"
                      className="bg-transparent border border-gray border-opacity-35 rounded-medium pl-1 py-0.75"
                      value={initialAssessmentLasts}
                      onChange={handleInitialAssessment}
                    />
                  </div>
                ) : (
                  <div className="pr-3 text-h2">{initialAssessmentLasts}</div>
                )}
              </div>
            </div>
          </div>
          {editConfigureInitialTesting && (
            <div className="flex justify-end pb-3 pr-1">
              <ButtonOutlinedGreen
                onClick={() => setEditConfigureInitialTesting(false)}
              >
                Save
              </ButtonOutlinedGreen>
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center justify-between mt-2">
        <div className="mb-0.5 mt-2 text-h4">Configure Ongoing Testing</div>
        <ButtonOutlinedGreen
          onClick={() => {
            setEditConfigureOngoingTesting(true);
            setIsAccordionOpen(true);
          }}
        >
          Modify/Add
        </ButtonOutlinedGreen>
      </div>
      <div className="p-1 border bg-gray-background border-gray border-opacity-35 min-h-2 rounded-medium">
        <div className="font-medium text-h5">Organization rules</div>
        <div className="flex mt-3.125 mb-3.625">
          <div className="w-1/2">
            <div className="flex items-center justify-between">
              <div>Minimum tests per quarter</div>
              <div className="pr-4 text-h2">{configureOngoingMinTest}</div>
            </div>
          </div>
          <div className="w-1/2">
            <div className="flex items-center justify-between">
              <div>Minimum tests per quarter</div>
              <div className="pr-3 text-h2">{configureOngoingMaxTest}</div>
            </div>
          </div>
        </div>
        <div className="py-1 cursor-pointer border-y border-gray border-opacity-35">
          <div
            className="flex items-center justify-between"
            onClick={toggleAccordion}
          >
            <div className="ml-1">Custom rules for User Groups</div>
            <div className={`mr-2 ${isAccordionOpen ? "rotate-180" : ""}`}>
              <DropDownArrowIcon />
            </div>
          </div>
        </div>
        {isAccordionOpen && (
          <div>
            <div className="flex items-center justify-between mt-2.5">
              <div className="w-1/2">
                <div className="flex items-center justify-between">
                  <div>
                    <div className="text-h5">High Risk Users</div>
                    <div className="text-h6">Minimum tests per Quarter</div>
                  </div>
                  {editConfigureOngoingTesting ? (
                    <div className="flex items-center">
                      <input
                        type="text"
                        className="bg-transparent border border-gray border-opacity-35 rounded-medium pl-1 py-0.75 mr-3"
                        value={highRiskMinTestPerQuarter}
                        placeholder="Maximum tests"
                        onChange={handleHighMinTestPerQuarter}
                      />
                    </div>
                  ) : (
                    <div className="pr-4 text-h2">
                      {highRiskMinTestPerQuarter}
                    </div>
                  )}
                </div>
              </div>

              <div className="w-1/2">
                <div className="flex items-center justify-between">
                  <div>
                    <div className="text-h5">High Risk Users</div>
                    <div className="text-h6">Maximum tests per Quarter</div>
                  </div>
                  {editConfigureOngoingTesting ? (
                    <div className="flex items-center">
                      <input
                        type="text"
                        className="bg-transparent border border-gray border-opacity-35 rounded-medium pl-1 py-0.75 mr-3"
                        value={highRiskMaxTestPerQuarter}
                        placeholder="Maximum tests"
                        onChange={handleHighMaxTestPerQuarter}
                      />
                      <div>
                        <DeleteDumpsterIcon />
                      </div>
                    </div>
                  ) : (
                    <div className="pr-3 text-h2">
                      {highRiskMaxTestPerQuarter}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="my-2 text-gray text-opacity-35">
              <hr />
            </div>

            <div className="flex items-center justify-between mt-2.5 mb-2">
              <div className="w-1/2">
                <div className="flex items-center justify-between">
                  <div>
                    <div className="text-h5">Medium Risk Users</div>
                    <div className="text-h6">Minimum tests per Quarter</div>
                  </div>
                  {editConfigureOngoingTesting ? (
                    <div className="flex items-center">
                      <input
                        type="text"
                        className="bg-transparent border border-gray border-opacity-35 rounded-medium pl-1 py-0.75 mr-3"
                        value={mediumRiskMinTestPerQuarter}
                        placeholder="Maximum tests"
                        onChange={handleMedMinTestPerQuarter}
                      />
                    </div>
                  ) : (
                    <div className="pr-4 text-h2">
                      {mediumRiskMinTestPerQuarter}
                    </div>
                  )}
                </div>
              </div>

              <div className="w-1/2">
                <div className="flex items-center justify-between">
                  <div>
                    <div className="text-h5">Medium Risk Users</div>
                    <div className="text-h6">Maximum tests per Quarter</div>
                  </div>
                  {editConfigureOngoingTesting ? (
                    <div className="flex items-center">
                      <input
                        type="text"
                        className="bg-transparent border border-gray border-opacity-35 rounded-medium pl-1 py-0.75 mr-3"
                        value={mediumRiskMaxTestPerQuarter}
                        placeholder="Maximum tests"
                        onChange={handleMedMaxTestPerQuarter}
                      />
                      <div>
                        <DeleteDumpsterIcon />
                      </div>
                    </div>
                  ) : (
                    <div className="pr-3 text-h2">
                      {mediumRiskMaxTestPerQuarter}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {editConfigureOngoingTesting && (
              <div>
                <div className="text-gray text-opacity-35">
                  <hr />
                </div>
                <div className="flex items-center justify-center">
                  <div className="w-1/2">
                    <div className="flex items-start justify-between pt-3 pb-3 pr-3 mt-1">
                      <div>
                        <div>
                          <button
                            onClick={toggleSelectUserGroupDropdown}
                            className="bg-transparent px-1 py-0.75 border border-gray border-opacity-35 rounded-medium text-[#848483] relative mb-0.5"
                          >
                            <div className="flex items-center justify-between">
                              <div>Select User Group</div>
                              <div>
                                <DoubleArrowDropdownIcon />
                              </div>
                            </div>
                          </button>
                          {selectUserGroupDropdown && (
                            <div className="absolute z-10 px-1 bg-black border border-gray border-opacity-35 rounded-medium min-h-2 w-14">
                              <div className="py-0.5 border-y border-gray border-opacity-35 cursor-pointer">
                                Severe Risk Users
                              </div>
                              <div className="py-0.5 border-y border-gray border-opacity-35 cursor-pointer">
                                Low Risk Users
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="text-h6">Minimum tests per Quarter</div>
                      </div>
                      {editConfigureOngoingTesting ? (
                        <input
                          type="text"
                          className="bg-transparent border border-gray border-opacity-35 rounded-medium pl-1 py-0.75"
                          value={selectedGroupMaxTest}
                          placeholder="Maximum tests"
                          onChange={handleSelectedGroupMaxTest}
                        />
                      ) : (
                        <div className="text-h2">{selectedGroupMaxTest}</div>
                      )}
                    </div>
                  </div>
                  <div className="w-1/2">
                    <div className="flex items-center justify-between pt-3 pb-4 pr-5">
                      <div>
                        <div className="text-h5 text-[#848483]">
                          Selected User Group
                        </div>
                        <div className="text-h6">Minimum tests per Quarter</div>
                      </div>
                      {editConfigureOngoingTesting ? (
                        <input
                          type="text"
                          className="bg-transparent border border-gray border-opacity-35 rounded-medium pl-1 py-0.75"
                          value={selectedGroupMinTest}
                          placeholder="Minimum tests"
                          onChange={handleSelectedGroupMinTest}
                        />
                      ) : (
                        <div className="text-h2">{selectedGroupMinTest}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="flex justify-end">
              <ButtonOutlinedGreen
                onClick={() => setEditConfigureOngoingTesting(false)}
              >
                Save
              </ButtonOutlinedGreen>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfigureTestingSchedule;
