import React from "react";

const AdaptLogo = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_232_23161"
        style={{ maskType: "alpha" }} // Updated syntax for style
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="30"
        height="30"
      >
        <rect
          x="0.366302"
          y="0.471886"
          width="28.741"
          height="28.741"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_232_23161)">
        <path
          d="M13.4794 19.0937L20.2455 12.3276L18.539 10.6211L13.4794 15.6807L10.9645 13.1658L9.25805 14.8723L13.4794 19.0937ZM14.7368 26.8178C11.9625 26.1193 9.6722 24.5275 7.8659 22.0426C6.05961 19.5577 5.15646 16.7984 5.15646 13.7646V6.4596L14.7368 2.86697L24.3171 6.4596V13.7646C24.3171 16.7984 23.414 19.5577 21.6077 22.0426C19.8014 24.5275 17.5111 26.1193 14.7368 26.8178Z"
          fill="#00FF82"
        />
      </g>
    </svg>
  );
};

export default AdaptLogo;
