import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { NavigationList } from "./utils";

import { LogoItem } from "../../common";

import {
  pagesPlatform,
  pagesPlatformCraftVentures,
  pagesPlatformEndUser,
  pagesPlatformManager,
  pagesPlatformOnboarding,
} from "../../../constants";

function NavigationPlatformSide({
  email,
  organization,
  isInitialLoading,
  isBRACompleted,
  isAdmin,
  isManager,
  msspOrganizations,
  selectedMSSPOrganization,
  onClickMSSPOrganization,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;

  const [open, setOpen] = useState({
    Insights: location.pathname.startsWith("/insights/"),
    Configure: location.pathname.startsWith("/configure/"),
  });

  const getUpdatedOpen = useCallback(() => {
    const insights = location.pathname.startsWith("/insights/");
    const configure = location.pathname.startsWith("/configure/");

    return {
      Insights: insights,
      Configure: configure,
    };
  }, [location.pathname]);

  const handleClickExpand = (key) => {
    const updatedOpen = { ...open };
    updatedOpen[key] = !updatedOpen[key];
    setOpen(updatedOpen);
  };

  const handleClickPageWithSubpages = (path) => {
    navigate(path);
  };

  useEffect(() => {
    setOpen(getUpdatedOpen());
  }, [getUpdatedOpen]);

  return (
    <div className="sticky top-0 flex flex-col w-full h-screen py-1.25 px-0.625">
      <div className="flex justify-center">
        <LogoItem height="39px" />
      </div>
      <div className="flex flex-col justify-between h-full">
        <div className="flex-grow py-1.875">
          <nav>
            {!isInitialLoading && (
              <>
                {isAdmin && !isBRACompleted && (
                  <NavigationList
                    header="Onboarding"
                    pages={pagesPlatformOnboarding}
                    open={open}
                    pathname={pathname}
                    msspOrganizations={msspOrganizations}
                    selectedMSSPOrganization={selectedMSSPOrganization}
                    onClickExpand={handleClickExpand}
                    onClickMSSPOrganization={onClickMSSPOrganization}
                    onClickPageWithSubpages={handleClickPageWithSubpages}
                  />
                )}
                {isAdmin && isBRACompleted && (
                  <NavigationList
                    header="Company Metrics"
                    pages={pagesPlatform}
                    open={open}
                    pathname={pathname}
                    msspOrganizations={msspOrganizations}
                    selectedMSSPOrganization={selectedMSSPOrganization}
                    onClickExpand={handleClickExpand}
                    onClickMSSPOrganization={onClickMSSPOrganization}
                    onClickPageWithSubpages={handleClickPageWithSubpages}
                  />
                )}
                {isManager && isBRACompleted && (
                  <NavigationList
                    header="Team Metrics"
                    pages={pagesPlatformManager}
                    open={open}
                    pathname={pathname}
                    msspOrganizations={msspOrganizations}
                    selectedMSSPOrganization={selectedMSSPOrganization}
                    onClickExpand={handleClickExpand}
                    onClickMSSPOrganization={onClickMSSPOrganization}
                    onClickPageWithSubpages={handleClickPageWithSubpages}
                  />
                )}
                {email !== "david@dune.demo" && isBRACompleted && (
                  <NavigationList
                    header="My Metrics"
                    pages={pagesPlatformEndUser}
                    open={open}
                    pathname={pathname}
                    msspOrganizations={msspOrganizations}
                    selectedMSSPOrganization={selectedMSSPOrganization}
                    onClickExpand={handleClickExpand}
                    onClickMSSPOrganization={onClickMSSPOrganization}
                    onClickPageWithSubpages={handleClickPageWithSubpages}
                    padding={isAdmin || isManager ? "pt-4.375" : ""}
                  />
                )}
                {/* Custom page for integrations */}
                {isAdmin &&
                  (organization === "Craft Ventures" ||
                    organization === "OSF HealthCare" ||
                    organization === "Osf Healthcare") && (
                    <NavigationList
                      header="Settings"
                      pages={pagesPlatformCraftVentures}
                      open={open}
                      pathname={pathname}
                      msspOrganizations={msspOrganizations}
                      selectedMSSPOrganization={selectedMSSPOrganization}
                      onClickExpand={handleClickExpand}
                      onClickMSSPOrganization={onClickMSSPOrganization}
                      onClickPageWithSubpages={handleClickPageWithSubpages}
                      padding={isAdmin || isManager ? "pt-4.375" : ""}
                    />
                  )}
              </>
            )}
          </nav>
        </div>
        <div>
          <p className="copyright">Copyright © 2024 Dune Security</p>
        </div>
      </div>
    </div>
  );
}

export default NavigationPlatformSide;
