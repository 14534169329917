export const headers = [
  {
    id: "select",
    label: "Select",
    isSortable: false,
    isSearchable: false,
    align: "center",
    width: "20px",
  },
  {
    id: "name",
    label: "Name",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "252px",
  },
  {
    id: "department",
    label: "Department",
    isSortable: true,
    isSearchable: true,
    align: "center",
    width: "208px",
  },
  {
    id: "riskScore",
    label: "Risk score",
    isSortable: true,
    isSearchable: false,
    align: "center",
  },
  {
    id: "riskLevel",
    label: "Risk level",
    isSortable: false,
    isSearchable: false,
    align: "center",
    width: "164px",
  },
];
