import React, { useCallback, useEffect, useState } from "react";

import { Box, Grid, Typography } from "@mui/material";

import { getUsers } from "../../../api";

import { UsersTable } from "../../../components";

import { headersUsersTable } from "../../../utils/constants/platform/employees";

import "./employees.css";

function Employees({ isInitialLoading, email }) {
  // Users
  const [users, setUsers] = useState([]);

  const loadUsers = useCallback(async () => {
    const usersData = await getUsers(false);

    if (Object.keys(usersData.error).length > 0) {
      console.error(usersData.error.message);
    } else {
      const users = usersData.result;

      setUsers(users);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const updateComponent = async () => {
      await loadUsers();
    };

    if (!isInitialLoading && email) {
      updateComponent();
    }
  }, [isInitialLoading, email, loadUsers]);

  return (
    <Box marginTop="60px">
      <Typography
        role="heading"
        aria-level="2"
        component="h2"
        className="dune-text-header-dashboard"
      >
        Provision users
      </Typography>
      <Typography component="p" marginTop="20px">
        Add your users to the system to ensure a personalized defense strategy
        by going through your identity provider.
      </Typography>
      <Grid item xs={12}>
        <UsersTable
          data={users}
          headers={headersUsersTable}
          defaultOrderBy="name"
          disableFilters={true}
          disableProfilePage={true}
        />
      </Grid>
    </Box>
  );
}

export default Employees;
