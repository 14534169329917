import React, { useEffect, useState } from "react";

import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

function TrainingPieChart({ trainingCompletion, onClickCell }) {
  const COLORS = ["#00ff82", "#00994e", "#006634"];

  const [pieChartData, setPieChartData] = useState([]);

  const handleClickCell = (status) => {
    onClickCell(status);
  };

  useEffect(() => {
    if (trainingCompletion) {
      setPieChartData(
        Object.entries(trainingCompletion).map(([status, percentage]) => ({
          status,
          percentage,
        })),
      );
    }
  }, [trainingCompletion]);

  return (
    <ResponsiveContainer width="100%" height="100%" minHeight="200px">
      <PieChart width={200} height={200}>
        {/* Chart Title */}
        <text
          x="50%"
          y="50%"
          className="detailed"
          textAnchor="middle"
          dominantBaseline="middle"
          fill="#ffffff"
        >
          Total Users
        </text>
        <Pie
          data={pieChartData}
          cx="50%"
          cy="50%"
          innerRadius={40} // Adjust for a smaller inner radius.
          outerRadius="100%"
          labelLine={false}
          fill="#8884d8"
          stroke="none"
          startAngle={90} // Start at the top.
          endAngle={-270} // Complete the full circle.
          dataKey="percentage"
        >
          {pieChartData.map((entry, index) => (
            <Cell
              role="button"
              className="cursor-pointer focus:outline-none"
              fill={COLORS[index % COLORS.length]}
              onClick={() => handleClickCell(entry.status)}
              key={`cell-${index}`}
            />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}

export default TrainingPieChart;
