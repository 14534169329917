import React from "react";

const TrainingFilledIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
    >
      <path
        d="M11.6999 0.805045C11.7946 0.763748 11.8967 0.742432 11.9999 0.742432C12.1032 0.742432 12.2053 0.763748 12.2999 0.805045C15.998 2.42145 19.5255 4.40294 22.8299 6.72005C22.9464 6.80171 23.0372 6.91481 23.0918 7.04614C23.1464 7.17748 23.1626 7.32163 23.1384 7.46179C23.1141 7.60195 23.0506 7.73233 22.9551 7.83773C22.8596 7.94312 22.736 8.01919 22.5989 8.05705C19.1683 9.00454 15.8488 10.316 12.6969 11.969L12.6939 11.971L12.3539 12.151C12.2452 12.2092 12.1238 12.2396 12.0004 12.2396C11.8771 12.2396 11.7557 12.2092 11.6469 12.151C10.2956 11.4289 8.91183 10.7692 7.49994 10.174V9.95004C7.49925 9.88775 7.51474 9.82635 7.54489 9.77184C7.57503 9.71733 7.61881 9.67157 7.67194 9.63905C9.18131 8.72436 10.7341 7.88337 12.3249 7.11905C12.5042 7.03285 12.6419 6.87896 12.7078 6.69124C12.7736 6.50352 12.7621 6.29733 12.6759 6.11805C12.5897 5.93876 12.4359 5.80106 12.2481 5.73523C12.0604 5.66941 11.8542 5.68085 11.6749 5.76705C10.0407 6.55234 8.44552 7.41635 6.89494 8.35605C6.67434 8.48987 6.48403 8.66817 6.33614 8.8796C6.18824 9.09102 6.08601 9.33093 6.03594 9.58405C4.51715 8.99964 2.97063 8.49003 1.40194 8.05705C1.26483 8.01919 1.14132 7.94312 1.04581 7.83773C0.9503 7.73233 0.886728 7.60195 0.862512 7.46179C0.838297 7.32163 0.854435 7.17748 0.909045 7.04614C0.963655 6.91481 1.05449 6.80171 1.17094 6.72005C4.47509 4.40301 8.00223 2.42152 11.6999 0.805045Z"
        fill="white"
      />
      <path
        d="M13.0598 13.4731C15.516 12.1608 18.0809 11.0627 20.7258 10.1911C20.8609 11.616 20.946 13.0452 20.9808 14.4761C20.9844 14.627 20.9425 14.7754 20.8604 14.9021C20.7784 15.0288 20.66 15.1277 20.5208 15.1861C17.6861 16.37 14.9719 17.8241 12.4158 19.5281C12.2926 19.6102 12.1478 19.654 11.9998 19.654C11.8517 19.654 11.707 19.6102 11.5838 19.5281C9.02798 17.8241 6.31413 16.3701 3.47977 15.1861C3.34037 15.1279 3.22178 15.029 3.13952 14.9023C3.05726 14.7756 3.01518 14.6271 3.01877 14.4761C3.05377 13.0341 3.13977 11.6061 3.27377 10.1901C4.19212 10.4928 5.10119 10.8229 5.99977 11.1801V12.4501C5.7837 12.5746 5.60208 12.7509 5.47133 12.9633C5.34058 13.1756 5.26483 13.4172 5.25094 13.6662C5.23704 13.9151 5.28543 14.1637 5.39173 14.3892C5.49804 14.6148 5.6589 14.8103 5.85977 14.9581C5.76977 15.3381 5.63777 15.7111 5.46277 16.0681C5.91477 16.2811 6.36377 16.5021 6.80877 16.7291C7.05967 16.2185 7.24481 15.6782 7.35977 15.1211C7.61265 15.0086 7.8295 14.8284 7.98632 14.6004C8.14313 14.3723 8.2338 14.1054 8.24829 13.829C8.26278 13.5526 8.20053 13.2776 8.06843 13.0344C7.93632 12.7912 7.73951 12.5893 7.49977 12.4511V11.8061C8.66789 12.3167 9.81544 12.8731 10.9398 13.4741C11.2659 13.6483 11.63 13.7394 11.9998 13.7394C12.3695 13.7394 12.7336 13.6483 13.0598 13.4741V13.4731Z"
        fill="white"
      />
      <path
        d="M4.46232 17.462C4.88232 17.043 5.21532 16.572 5.46232 16.068C5.91532 16.281 6.36432 16.502 6.80932 16.729C6.4821 17.3936 6.04762 17.9997 5.52332 18.523C5.45466 18.5967 5.37186 18.6558 5.27986 18.6968C5.18786 18.7378 5.08854 18.7598 4.98784 18.7616C4.88714 18.7634 4.78711 18.7448 4.69372 18.7071C4.60033 18.6694 4.5155 18.6132 4.44428 18.542C4.37306 18.4708 4.31692 18.386 4.27919 18.2926C4.24147 18.1992 4.22295 18.0992 4.22473 17.9985C4.2265 17.8978 4.24854 17.7985 4.28954 17.7065C4.33053 17.6145 4.38963 17.5317 4.46332 17.463L4.46232 17.462Z"
        fill="white"
      />
    </svg>
  );
};

export default TrainingFilledIcon;
