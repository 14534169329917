import React from "react";

const DeleteDumpsterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <mask
        id="mask0_903_2886"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="30"
        height="31"
      >
        <rect y="0.00976562" width="30" height="30" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_903_2886)">
        <path
          d="M9.5766 24.8174C9.04369 24.8174 8.58994 24.6301 8.21535 24.2555C7.84077 23.8808 7.65348 23.427 7.65348 22.8943V7.56742H6.42285V6.41742H11.2882V5.49023H18.7304V6.41336H23.596V7.56742H22.365V22.8999C22.365 23.4366 22.1796 23.8903 21.8088 24.2612C21.438 24.632 20.9823 24.8174 20.4419 24.8174H9.5766ZM21.2113 7.56742H8.80754V22.8943C8.80754 23.1187 8.87962 23.3029 9.02379 23.4471C9.16796 23.5913 9.35223 23.6634 9.5766 23.6634H20.4419C20.6342 23.6634 20.8106 23.5833 20.971 23.423C21.1312 23.2628 21.2113 23.0866 21.2113 22.8943V7.56742ZM12.3554 21.1862H13.5094V10.0324H12.3554V21.1862ZM16.5094 21.1862H17.6632V10.0324H16.5094V21.1862Z"
          fill="#ECECEB"
        />
      </g>
    </svg>
  );
};

export default DeleteDumpsterIcon;
