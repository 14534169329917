import React, { useState, useEffect } from "react";
import {
  ButtonOutlinedGray,
  // ButtonOutlinedGreen,
  SortIcon,
  // Tooltip,
  VideoPlayer,
} from "../../../components/";
import {
  ConfigureLayout,
  ConfigureTrainingCard,
  SearchInput,
} from "../../../components/platform/configure";
import {
  getTrainingDashboardData,
  patchChangeTrainingAssetStatus,
} from "../../../api";
import { EyeIcon, GridIcon, ListIcon, LoadingSpinner } from "../../../utils";
import { capitalizeFirstWords } from "../../../utils/helper-functions/capitalize";
import { truncate } from "../../../utils/helper-functions/truncate";
import { Pagination } from "../../../components/common/users-table/utils";

const TrainingDashboard = () => {
  const [dashboardData, setDashboardData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [activeDiv, setActiveDiv] = useState(2);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: "ascending",
  });
  const [titleFilterOptions, setTitleFilterOptions] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [showAll, setShowAll] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [videoURL, setVideoURL] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const [assetStatus, setAssetStatus] = useState({});

  const handleOpenVideo = (title, file_name_video) => {
    setVideoTitle(title);
    setVideoURL(file_name_video);
    setIsOpen(true);
  };

  const handleCloseVideo = () => {
    setIsOpen(false);
    setVideoTitle("");
    setVideoURL("");
  };

  const itemsPerPage = 12;
  const defaultFilterOptions = [
    {
      categoryName: "Status",
      categories: [
        { category: "active", categoryDisplayName: "Active" },
        { category: "passive", categoryDisplayName: "Paused" },
      ],
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const trainingData = await getTrainingDashboardData();
      setDashboardData(trainingData.data.data);
      setFilteredData(trainingData.data.data);

      const uniqueTitles = trainingData.data.data.reduce((acc, training) => {
        if (!acc.some((item) => item.category === training.title)) {
          acc.push({
            category: training.title,
            categoryDisplayName: capitalizeFirstWords(training.title),
          });
        }
        return acc;
      }, []);

      const savedStatus = JSON.parse(localStorage.getItem("assetStatus")) || {};
      const statusMap = trainingData.data.data.reduce((acc, training) => {
        acc[training.title] = savedStatus[training.title] ?? training.is_active;
        return acc;
      }, {});
      setAssetStatus(statusMap);
      setTitleFilterOptions(uniqueTitles);
    };

    fetchData();
  }, []);

  const paginatedData = filteredData.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage,
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const combinedFilterOptions = [
    ...defaultFilterOptions,
    { categoryName: "Title", categories: titleFilterOptions },
  ];

  const toggleSwitch = async (title) => {
    const currentStatus = assetStatus[title];
    const requestBody = {
      title: title,
      set_to_inactive: currentStatus,
    };

    try {
      const response = await patchChangeTrainingAssetStatus(requestBody);
      console.log("response", response);

      setAssetStatus((prevStatus) => {
        const updatedStatus = { ...prevStatus, [title]: !currentStatus };
        localStorage.setItem("assetStatus", JSON.stringify(updatedStatus));

        const updatedFilteredData = dashboardData.filter((training) => {
          const matchesFilters = activeFilters.every((filter) => {
            if (filter.category === "Status") {
              return filter.subCategory === "active"
                ? updatedStatus[training.title]
                : !updatedStatus[training.title];
            } else if (filter.category === "Title") {
              return training.title === filter.subCategory;
            }
            return true;
          });

          const matchesSearchQuery =
            training.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            (updatedStatus[training.title]
              ? "active".includes(searchQuery.toLowerCase())
              : "paused".includes(searchQuery.toLowerCase()));

          return matchesFilters && matchesSearchQuery;
        });

        setFilteredData(updatedFilteredData);
        return updatedStatus;
      });
    } catch (error) {
      console.error("Failed to update status:", error);
    }
  };

  useEffect(() => {
    const updatedFilteredData = dashboardData.filter((training) => {
      const matchesFilters = activeFilters.every((filter) => {
        if (filter.category === "Status") {
          return filter.subCategory === "active"
            ? assetStatus[training.title]
            : !assetStatus[training.title];
        } else if (filter.category === "Title") {
          return training.title === filter.subCategory;
        }
        return true;
      });

      const matchesSearchQuery =
        training.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (assetStatus[training.title]
          ? "active".includes(searchQuery.toLowerCase())
          : "paused".includes(searchQuery.toLowerCase()));

      return matchesFilters && matchesSearchQuery;
    });

    setFilteredData(updatedFilteredData);
  }, [assetStatus, activeFilters, searchQuery, dashboardData]);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const searchResults = dashboardData.filter((training) => {
      const matchesTitle = training.title
        .toLowerCase()
        .includes(query.toLowerCase());
      const matchesStatus = training.is_active
        ? "active".includes(query.toLowerCase())
        : "paused".includes(query.toLowerCase());

      return matchesTitle || matchesStatus;
    });

    if (activeFilters.length > 0) {
      const filteredResults = searchResults.filter((training) => {
        return activeFilters.some((filter) => {
          if (filter.category === "Status") {
            return filter.subCategory === "active"
              ? training.is_active
              : !training.is_active;
          } else if (filter.category === "Title") {
            return training.title === filter.subCategory;
          }
          return true;
        });
      });
      setFilteredData(filteredResults);
    } else {
      setFilteredData(searchResults);
    }
  };

  const handleClick = (id) => {
    setActiveDiv(id);
  };

  const sortData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });

    const sortedData = [...filteredData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setFilteredData(sortedData);
  };

  const handleFilter = (category, subCategory) => {
    setActiveFilters((prevFilters) => {
      const filterExists = prevFilters.find(
        (filter) =>
          filter.category === category && filter.subCategory === subCategory,
      );

      const updatedFilters = filterExists
        ? prevFilters.filter(
            (filter) =>
              !(
                filter.category === category &&
                filter.subCategory === subCategory
              ),
          )
        : [...prevFilters, { category, subCategory }];

      const filtered = dashboardData.filter((training) => {
        return (
          updatedFilters.length === 0 ||
          updatedFilters.some((filter) => {
            if (filter.category === "Status") {
              return filter.subCategory === "active"
                ? training.is_active
                : !training.is_active;
            } else if (filter.category === "Title") {
              return training.title === filter.subCategory;
            }
            return true;
          })
        );
      });

      const finalFilteredData = filtered.filter((training) => {
        const matchesTitle = training.title
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        const matchesStatus = training.is_active
          ? "active".includes(searchQuery.toLowerCase())
          : "paused".includes(searchQuery.toLowerCase());

        return matchesTitle || matchesStatus;
      });

      setFilteredData(finalFilteredData);

      return updatedFilters;
    });
  };

  if (!paginatedData) {
    <div className="h-screen w-screen flex justify-center">
      <div>
        <LoadingSpinner />
      </div>
      <div>Loading your training asset</div>
    </div>;
  }

  return (
    <div className="text-white">
      <div className="flex justify-between items-center mt-1.75">
        <div className="flex items-center">
          <div className="mr-0.25">Training Library</div>
          {/* <Tooltip text="Training Library helps you to manage all the trainings for your organization." /> */}
        </div>
      </div>
      <ConfigureLayout
        filters={
          <>
            {combinedFilterOptions.map((filterOption) => (
              <div key={filterOption.categoryName}>
                <div className="flex items-center mb-0.75 text-gray uppercase tracking-custom-1.08 text-h6">
                  {filterOption.categoryName}
                </div>
                <div>
                  {filterOption.categories
                    .slice(0, showAll ? filterOption.categories.length : 5)
                    .map((cname) => (
                      <div
                        className="flex items-center mb-0.5 ml-0.5"
                        key={cname.category}
                      >
                        <input
                          type="checkbox"
                          disabled={false}
                          checked={activeFilters.some(
                            (filter) =>
                              filter.category === filterOption.categoryName &&
                              filter.subCategory === cname.category,
                          )}
                          onChange={() =>
                            handleFilter(
                              filterOption.categoryName,
                              cname.category,
                            )
                          }
                          className={`mr-0.5 appearance-none border rounded border-gray min-h-1 min-w-1 text-black checked:bg-green checked:border-none focus:outline-none checked:shadow-[0px_0px_18px_0px_#028334]`}
                        />
                        <div className="text-gray text-h5">
                          {cname.categoryDisplayName}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))}
            <button
              className="text-green text-h6"
              onClick={() => setShowAll((prevShowAll) => !prevShowAll)}
            >
              {showAll ? "Show Less" : "See More"}
            </button>
          </>
        }
        data={
          <>
            <div className="flex items-center justify-between mb-2">
              <SearchInput
                searchQuery={searchQuery}
                onSearchChange={handleSearchChange}
                didFetchAllPages={true}
                placeholderTextWhenLoaded="Type in a training's name"
                placeholderTextWhenLoading="Loading all the trainings for you"
              />
              <div className="flex mt-0.5 cursor-pointer">
                <div
                  onClick={() => handleClick(1)}
                  className={`rounded-l-small p-0.5 cursor-pointer ${
                    activeDiv === 1
                      ? "border border-green text-black"
                      : "bg-transparent text-white border border-gray border-opacity-35"
                  }`}
                >
                  <ListIcon isActive={activeDiv === 1} />
                </div>
                <div
                  onClick={() => handleClick(2)}
                  className={`rounded-r-small p-0.5 cursor-pointer ${
                    activeDiv === 2
                      ? "border border-green text-black shadow-custom-int"
                      : "bg-transparent text-white border border-gray border-opacity-35"
                  }`}
                >
                  <GridIcon isActive={activeDiv === 2} />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-3 gap-2 mr-0.75 w-full">
              {activeDiv === 1 ? (
                <div className="mt-1 w-full col-span-3">
                  <table className="w-full table-fixed">
                    <thead>
                      <tr className="border-b border-gray border-opacity-35">
                        <th className="w-1.5 truncate text-gray uppercase tracking-custon-1.08 text-h6 text-left">
                          <div className="flex items-center space-x-0.313">
                            <span>View</span>
                          </div>
                        </th>
                        <th className="w-3 truncate text-gray uppercase tracking-custon-1.08 text-h6 text-left cursor-pointer">
                          <div className="flex items-center space-x-0.313">
                            <span>Thumbnail</span>
                          </div>
                        </th>
                        <th
                          className="w-4 truncate text-gray uppercase tracking-custon-1.08 text-h6 text-left cursor-pointer"
                          onClick={() => sortData("title")}
                        >
                          <div className="flex items-center space-x-0.313">
                            <span>Title</span>
                            <SortIcon />
                          </div>
                        </th>
                        <th
                          className="w-3 truncate text-gray uppercase tracking-custon-1.08 text-h6 text-left cursor-pointer"
                          onClick={() => sortData("duration")}
                        >
                          <div className="flex items-center space-x-0.313">
                            <span>Duration</span>
                            <SortIcon />
                          </div>
                        </th>
                        <th
                          className="w-5 truncate text-gray uppercase tracking-custon-1.08 text-h6 text-left cursor-pointer"
                          onClick={() => sortData("is_active")}
                        >
                          <div className="flex items-center space-x-0.313">
                            <span>Status</span>
                            <SortIcon />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedData.map((training) => (
                        <tr
                          key={training._id}
                          className="h-4 border-b border-gray border-opacity-35"
                        >
                          <td className="overflow-hidden truncate">
                            <div className="ml-0.25">
                              <ButtonOutlinedGray
                                className="ml-1"
                                padding="p-[5px]"
                                title="View this asset."
                                onClick={() =>
                                  handleOpenVideo(
                                    training.title,
                                    training.file_name_video,
                                  )
                                }
                              >
                                <div className="w-[16px] h-[16px] cursor-pointer">
                                  <EyeIcon />
                                </div>
                              </ButtonOutlinedGray>
                            </div>
                          </td>
                          <td className="overflow-hidden truncate py-1">
                            <img
                              aria-label="Cover"
                              src={`https://d36cattz2ccgpt.cloudfront.net/general/${training.file_name_thumbnail}`}
                              alt="Cover"
                              className="h-4.5 rounded-t-medium"
                            />
                          </td>
                          <td className="overflow-hidden truncate">
                            {capitalizeFirstWords(
                              truncate(training.title || "N/A", 25),
                            )}
                          </td>
                          <td className="overflow-hidden truncate ">
                            {training.duration} mins
                          </td>
                          <td className="overflow-hidden truncate cursor-pointer">
                            <div className="flex items-center">
                              <div className="w-3 text-h6">
                                {assetStatus[training.title]
                                  ? "Active"
                                  : "Inactive"}
                              </div>
                              <div
                                onClick={() => toggleSwitch(training.title)}
                                className={`w-2.25 h-1.125 flex items-center rounded-full p-0.25 cursor-pointer transition-colors duration-300 ml-0.25 ${
                                  assetStatus[training.title]
                                    ? "bg-green"
                                    : "bg-gray"
                                }`}
                              >
                                <div
                                  className={`bg-white w-0.75 h-0.75 rounded-full shadow-md transform transition-transform duration-300 ${
                                    assetStatus[training.title]
                                      ? "translate-x-1"
                                      : "-translate-x-0"
                                  }`}
                                ></div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                paginatedData.map((training) => (
                  <ConfigureTrainingCard
                    key={training._id}
                    onClick={() => toggleSwitch(training.title)}
                    toggleOn={assetStatus[training.title]}
                    {...training}
                  />
                ))
              )}
            </div>
            <div className="flex justify-start mt-1">
              <Pagination
                page={currentPage}
                setPage={setCurrentPage}
                totalPages={totalPages}
              />
            </div>
          </>
        }
      />
      <VideoPlayer
        isOpen={isOpen}
        onClose={handleCloseVideo}
        videoURL={videoURL}
        title={videoTitle}
      />
    </div>
  );
};

export default TrainingDashboard;
