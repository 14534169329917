import React from "react";

const ConfigureLayout = ({ filters, data }) => {
  return (
    <div className="w-full border border-gray border-opacity-35 rounded-small p-0.625 mt-0.5">
      <div className="flex items-start justify-between space-x-1">
        <div className="border-r border-gray border-opacity-35 p-0.5 w-18">
          {filters}
        </div>
        <div className="w-full">{data}</div>
      </div>
    </div>
  );
};

export default ConfigureLayout;
