import React, { useState } from "react";

import { AssetViewer } from "./utils";

import { CardWithLoadingState, Select, UsersTable } from "../../../../common";
import {
  formatDateFive,
  formatDateForValue,
} from "../../../../../utils/helper-functions/formatDate";

function CardSimulationTracker({
  simulationAssets,
  selectedDate,
  testHTML,
  onChangeSelectedDate,
  onClickViewTestAsset,
}) {
  const [selectedAssetName, setSelectedAssetName] = useState("");
  const [selectedRecipients, setSelectedRecipients] = useState(null);

  // Modal
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [title, setTitle] = useState("");

  const handleChangeSelectedDate = (event) => {
    setSelectedAssetName("");
    setSelectedRecipients(null);
    onChangeSelectedDate(event.target.value);
  };

  const handleClickRecipients = (recipients, assetName) => {
    if (recipients) {
      setSelectedRecipients(recipients);
    }

    if (assetName) {
      setSelectedAssetName(assetName);
    }
  };

  const handleClickViewTestAsset = async (assetName, title) => {
    if (assetName) {
      await onClickViewTestAsset(assetName);
      handleOpenModal(title);
    }
  };

  const handleCloseModal = () => {
    setTitle("");
    setIsOpenModal(false);
  };

  const handleOpenModal = (title) => {
    setTitle(title);
    setIsOpenModal(true);
  };

  const headersSimulationAssets = [
    {
      id: "viewAsset",
      label: "View",
      isSortable: false,
      isSearchable: false,
      align: "center",
      width: "56px",
      onClick: handleClickViewTestAsset,
    },
    {
      id: "assetName",
      label: "Scheduled Asset",
      isSortable: true,
      isSearchable: true,
      align: "left",
      width: "400px",
    },
    {
      id: "sender",
      label: "Sender",
      isSortable: true,
      isSearchable: true,
      align: "left",
      width: "164px",
    },
    {
      id: "recipients",
      label: "Recipients",
      isSortable: true,
      isSearchable: false,
      align: "left",
      width: "164px",
      onClick: handleClickRecipients,
    },
    {
      id: "difficulty",
      label: "Difficulty",
      isSortable: true,
      isSearchable: true,
      align: "left",
      width: "100px",
    },
    {
      id: "motive",
      label: "Motive",
      isSortable: true,
      isSearchable: true,
      align: "left",
      width: "100px",
    },
    {
      id: "method",
      label: "Method",
      isSortable: true,
      isSearchable: true,
      align: "left",
      width: "200px",
    },
  ];

  const headersUsers = [
    {
      id: "name",
      label: "Name",
      isSortable: true,
      isSearchable: true,
      align: "left",
      width: "252px",
    },
    {
      id: "email",
      label: "Email",
      isSortable: true,
      isSearchable: true,
      align: "left",
      width: "208px",
    },
    {
      id: "riskScore",
      label: "Risk score",
      isSortable: true,
      isSearchable: false,
      align: "center",
    },
    {
      id: "riskLevel",
      label: "Risk level",
      isSortable: false,
      isSearchable: false,
      align: "center",
      width: "164px",
    },
  ];

  const getDateOptions = () => {
    const options = [];
    const today = new Date();

    // Loop to get the next 30 days.
    for (let i = 0; i < 30; i++) {
      const futureDate = new Date();
      futureDate.setDate(today.getDate() + i);

      // Format for value and display.
      const valueString = formatDateForValue(futureDate);
      const displayString = formatDateFive(futureDate);

      options.push(
        <option key={i} value={valueString}>
          {displayString}
        </option>,
      );
    }

    return options;
  };

  return (
    <>
      <CardWithLoadingState
        loadingVariable={simulationAssets}
        minHeight={
          !simulationAssets || simulationAssets.length > 0 ? "428px" : 0
        }
      >
        <div className="max-w-[200px] w-full">
          <Select value={selectedDate} onChange={handleChangeSelectedDate}>
            {getDateOptions()}
          </Select>
        </div>
        {simulationAssets !== null &&
          Array.isArray(simulationAssets) &&
          simulationAssets.length > 0 && (
            <>
              <div className="mt-1.25">
                <UsersTable
                  data={simulationAssets}
                  headers={headersSimulationAssets}
                  rowsPerPage={6}
                  disableSearchBar
                />
              </div>
              {selectedRecipients && (
                <div className="mt-1.25">
                  <UsersTable
                    data={selectedRecipients}
                    headers={headersUsers}
                    defaultOrderBy="name"
                    rowsPerPage={6}
                    customHeader={
                      <div className="flex flex-col">
                        <div>
                          <h4 className="card-header">Scheduled Asset</h4>
                        </div>
                        <div className="border-b-2 border-green w-fit">
                          <p role="heading" aria-level="4" className="h4">
                            {selectedAssetName}
                          </p>
                        </div>
                      </div>
                    }
                  />
                </div>
              )}
            </>
          )}
        {simulationAssets !== null &&
          Array.isArray(simulationAssets) &&
          simulationAssets.length === 0 && (
            <div className="mt-1.25">
              <div>
                <p className="description-emphasized">
                  No Tests Scheduled On This Date
                </p>
              </div>
              <div className="mt-0.625">
                <p className="description">
                  It looks like there are no phishing tests scheduled for
                  this date. This gives you and your team a great opportunity to
                  review past performance or prepare for upcoming tests.
                </p>
              </div>
              <div className="mt-0.625">
                <ul className="pl-1.25 list-disc">
                  <li className="description">
                    <span className="description-emphasized">
                      Review Past Results
                    </span>
                    : Dive into past reports to review past tests and
                    their engagement rates.
                  </li>
                  <li className="description">
                    <span className="description-emphasized">
                      Prepare for Future Tests
                    </span>
                    : Select future dates to review upcoming tests.
                  </li>
                  <li className="description">
                    <span className="description-emphasized">Need Help?</span>{" "}
                    Our team is always here to assist. Reach out if you'd like
                    to schedule new tests or have any questions.
                  </li>
                </ul>
              </div>
            </div>
          )}
      </CardWithLoadingState>
      <AssetViewer
        isOpen={isOpenModal}
        onCloseModal={handleCloseModal}
        testHTML={testHTML}
        title={title}
      />
    </>
  );
}

export default CardSimulationTracker;
