import React from "react";

const PhoneIcon = () => {
  return (
    <svg
      width="34"
      height="22"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4665 6.99134H13.629C13.629 3.95767 11.3779 1.66971 8.39217 1.66971V2.85243C10.7531 2.85243 12.4665 4.59279 12.4665 6.99134Z"
        fill="white"
      />
      <path
        d="M8.39793 5.21849C9.62024 5.21849 10.1416 5.74893 10.1416 6.99256H11.304C11.304 5.08543 10.2724 4.03577 8.39793 4.03577V5.21849ZM10.3869 8.43725C10.2752 8.33397 10.1284 8.27889 9.97758 8.28363C9.82673 8.28838 9.68359 8.35258 9.57839 8.46268L8.18752 9.91801C7.85274 9.85296 7.17968 9.63948 6.48687 8.93636C5.79405 8.23087 5.58423 7.5443 5.52204 7.20604L6.95126 5.79033C7.05961 5.68337 7.1228 5.53771 7.12747 5.38419C7.13213 5.23066 7.0779 5.08131 6.97626 4.96775L4.82864 2.56506C4.72695 2.45114 4.58562 2.38204 4.43466 2.37243C4.2837 2.36282 4.13501 2.41347 4.02016 2.51361L2.75891 3.61413C2.65842 3.71674 2.59844 3.85331 2.59035 3.99792C2.58163 4.14576 2.41541 7.64779 5.08438 10.3645C7.41275 12.7329 10.3293 12.9061 11.1326 12.9061C11.25 12.9061 11.3221 12.9026 11.3412 12.9014C11.4834 12.8933 11.6175 12.832 11.7179 12.7293L12.7989 11.4455C12.8974 11.3287 12.9473 11.1775 12.938 11.0239C12.9286 10.8703 12.8608 10.7265 12.749 10.6229L10.3869 8.43725Z"
        fill="white"
      />
    </svg>
  );
};

export default PhoneIcon;
