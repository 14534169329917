import React, { useCallback, useEffect, useState } from "react";

import { getDepartments } from "../../../api";

import { CardWithLoadingState, UsersTable } from "../../../components";

import { searchDepartmentHeaders } from "../../../utils";

const SearchDepartment = ({ isInitialLoading, email }) => {
  // Departments
  const [departments, setDepartments] = useState(null);

  const loadDepartments = useCallback(async () => {
    // Change data for the demo account.
    if (email === "david@dune.demo") {
      const departments = [
        {
          departmentName: "Capital",
          riskScore: 61,
        },
        {
          departmentName: "Engineering", // Founder Scouting
          riskScore: 26,
        },
        {
          departmentName: "Global Support",
          riskScore: 9,
        },
        {
          departmentName: "Information Technology", // Technology
          riskScore: 43,
        },
        {
          departmentName: "Investment",
          riskScore: 36,
        },
        {
          departmentName: "Legal", // Legal & Finance
          riskScore: 52,
        },
        {
          departmentName: "Marketing & Communications",
          riskScore: 8,
        },
        {
          departmentName: "Portfolio",
          riskScore: 9,
        },
        {
          departmentName: "Program",
          riskScore: 15,
        },
      ];

      setDepartments(departments);
    } else {
      const departmentsData = await getDepartments();

      if (Object.keys(departmentsData.error).length > 0) {
        console.error(departmentsData.error.message);
      } else {
        const { departments } = departmentsData.result;

        setDepartments(departments);
      }
    }
  }, [email]);

  useEffect(() => {
    const updateComponent = async () => {
      await loadDepartments();
    };

    if (!isInitialLoading && email) {
      updateComponent();
    }
  }, [isInitialLoading, email, loadDepartments]);

  return (
    <CardWithLoadingState
      loadingVariable={departments}
      //  minHeight="585px"
    >
      <UsersTable
        data={departments}
        headers={searchDepartmentHeaders}
        defaultOrderBy="departmentName"
      />
    </CardWithLoadingState>
  );
};

export default SearchDepartment;
