import React, { useState, useEffect } from "react";
import { createClient } from "contentful";
import { Link } from "react-router-dom";
import { formatDateTwo } from "../../../utils/helper-functions/formatDate";
import { ButtonOutlinedGray } from "../../../components";
import { BackIcon, NextIcon } from "../../../utils";

const BootcampCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [bootCampData, setBootCampData] = useState([]);

  const cardWidth = 384; // width of each card in pixels
  const visibleCards = 3; // number of visible cards

  const scrollLeft = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const scrollRight = () => {
    if (currentIndex < bootCampData.length - visibleCards) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  useEffect(() => {
    const fetchedBootcamp = async () => {
      if (process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN) {
        const client = createClient({
          space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
          accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
        });

        await client
          .getEntries({
            content_type: "resourcesBootcamp",
            order: "-fields.date,fields.title",
          })
          .then((response) => {
            const data = response.items.map((item) => {
              const { fields, sys } = item;
              return {
                id: sys.id,
                title: fields.title,
                href: `/resources/bootcamp/${fields.resourceID}`,
                thumbnailURL: fields.thumbnail.fields.file.url,
                date: fields.date,
              };
            });
            setBootCampData(data);
          })
          .catch(console.error);
      }
    };

    fetchedBootcamp();
  }, []);

  return (
    <div className="my-8 max-w-78.75 mx-auto text-white">
      <div className="mx-1.25">
        <p role="heading" aria-level="1.5" className="text-h1.5">
          Resources
        </p>
        <div className="flex flex-wrap items-center justify-between">
          <p className="text-h4 mt-0.625 mb-1 md:mb-0">
            Access expert insights, whitepapers, and tools to boost your
            cybersecurity.
          </p>
          <div className="items-center justify-end hidden space-x-1 md:flex md:justify-center">
            <ButtonOutlinedGray
              title="Scroll left."
              isSmall
              onClick={scrollLeft}
              disabled={currentIndex === 0}
            >
              <BackIcon />
            </ButtonOutlinedGray>
            <ButtonOutlinedGray
              title="Scroll right."
              isSmall
              onClick={scrollRight}
              disabled={currentIndex >= bootCampData.length - visibleCards}
            >
              <NextIcon />
            </ButtonOutlinedGray>
            <Link to="/resources/">
              <ButtonOutlinedGray
                title="View all."
                isSmall
                onClick={scrollLeft}
                disabled={currentIndex === 0}
              >
                View All
              </ButtonOutlinedGray>
            </Link>
          </div>
        </div>
      </div>

      <div className="my-2.5 ml-1.25">
        <div className="overflow-x-auto no-scrollbar">
          <div
            className="flex space-x-2 transition-transform duration-300"
            style={{
              transform: `translateX(-${currentIndex * cardWidth}px)`,
              width: `${bootCampData.length * cardWidth}px`,
            }}
          >
            {bootCampData.map((item, index) => (
              <div
                key={index}
                className="border cursor-pointer border-gray border-opacity-35 rounded-medium"
                style={{ width: `${cardWidth}px` }}
              >
                <img
                  src={item.thumbnailURL}
                  alt={item.title}
                  className="h-13.438 rounded-medium w-full"
                />
                <p className="text-gray mt-0.75 mb-0.625 mx-1.25">
                  {formatDateTwo(item.date)} • 5 min read
                </p>
                <p className="text-h4 mb-1.25 mx-1.25">{item.title}</p>
                <Link to={item.href}>
                  <ButtonOutlinedGray isSmall title="Read more.">
                    Read More
                  </ButtonOutlinedGray>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BootcampCarousel;
