import React, { useCallback } from "react";

import { useDropzone } from "react-dropzone";

import { ButtonOutlinedGreen } from "../buttons";

import { FileUploadIcon } from "../../../utils/icons";

const FileUpload = ({ selectedFile, setSelectedFile }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      // Handle file upload logic here.
      if (setSelectedFile) {
        setSelectedFile(acceptedFiles[0]); // Store the first file.
      }
    },
    [setSelectedFile],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/json": [],
    },
  });

  return (
    <div className="flex flex-col items-center">
      <div
        {...getRootProps()}
        className={`flex flex-col items-center justify-center py-2 px-4 border border-dashed rounded-medium cursor-pointer max-w-[450px] w-full
      ${isDragActive ? "border-green" : "border-border-color"} 
      transition-colors duration-200 ease-in-out`}
      >
        <input {...getInputProps()} />
        <div>
          <FileUploadIcon />
        </div>
        <div className="mt-1 text-center">
          <p className="h4">
            {isDragActive
              ? "Drop the file here"
              : "Select a file or Drag and drop here"}
          </p>
          <p className="body mt-0.5">JSON, no more than 10 MB</p>
        </div>
        <div className="mt-2">
          <ButtonOutlinedGreen>Select File</ButtonOutlinedGreen>
        </div>
      </div>
      {selectedFile && (
        <div className="mt-2">
          <p className="body">Current file: {selectedFile.name}</p>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
