import React from "react";

const CircleFilledGreenIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
    >
      <circle cx="4.60632" cy="4.19666" r="4" fill="#00FF82" />
    </svg>
  );
};

export default CircleFilledGreenIcon;
