import React from "react";

const List = ({ data = [], keyName = "" }) => {
  return (
    <ol className="list-none">
      {data.map((dataItem, index) => (
        <li
          className="flex items-center mt-1 description first:mt-1"
          key={`list-${index}`}
        >
          <span className="inline-block font-semibold border-b-2 border-green pb-0.125 max-w-1.25 w-full text-center mr-0.5">
            {index + 1}
          </span>
          <span className="inline-block">{dataItem[keyName]}</span>
        </li>
      ))}
    </ol>
  );
};

export default List;
