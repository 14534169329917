export const companyNameMapping = [
  {
    incomingName: "1password",
    formattedName: "1Password",
  },
  {
    incomingName: "Company-name",
    formattedName: "CompanyName",
  },
  {
    incomingName: "Adobe",
    formattedName: "Adobe",
  },
  {
    incomingName: "Amazon",
    formattedName: "Amazon",
  },
  {
    incomingName: "Booking",
    formattedName: "Booking.com",
  },
  {
    incomingName: "Faker",
    formattedName: "Faker",
  },
  {
    incomingName: "Chase",
    formattedName: "Chase",
  },
  {
    incomingName: "Conedison",
    formattedName: "ConEdison",
  },
  {
    incomingName: "Delta",
    formattedName: "Delta",
  },
  {
    incomingName: "Dhl",
    formattedName: "DHL",
  },
  {
    incomingName: "Draftkings",
    formattedName: "DraftKings",
  },
  {
    incomingName: "Ebay",
    formattedName: "eBay",
  },
  {
    incomingName: "Egencia",
    formattedName: "Egencia",
  },
  {
    incomingName: "Fastspring",
    formattedName: "FastSpring",
  },
  {
    incomingName: "Gitlab",
    formattedName: "GitLab",
  },
  {
    incomingName: "Google",
    formattedName: "Google",
  },
  {
    incomingName: "Uscs",
    formattedName: "USCS",
  },
  {
    incomingName: "Intuit",
    formattedName: "Intuit",
  },
  {
    incomingName: "Match",
    formattedName: "Match.com",
  },
  {
    incomingName: "Mercury",
    formattedName: "Mercury",
  },
  {
    incomingName: "Microsoft",
    formattedName: "Microsoft",
  },
  {
    incomingName: "Midjourney",
    formattedName: "MidJourney",
  },
  {
    incomingName: "Netflix",
    formattedName: "Netflix",
  },
  {
    incomingName: "Okta",
    formattedName: "Okta",
  },
  {
    incomingName: "Paypal",
    formattedName: "PayPal",
  },
  {
    incomingName: "Prosapient",
    formattedName: "ProSapient",
  },
  {
    incomingName: "Ringcentral",
    formattedName: "RingCentral",
  },
  {
    incomingName: "Sharepoint",
    formattedName: "SharePoint",
  },
  {
    incomingName: "Skype",
    formattedName: "Skype",
  },
  {
    incomingName: "Spacex",
    formattedName: "SpaceX",
  },
  {
    incomingName: "Stanford",
    formattedName: "Stanford",
  },
  {
    incomingName: "Surveymonkey",
    formattedName: "SurveyMonkey",
  },
  {
    incomingName: "Threads",
    formattedName: "Threads",
  },
  { incomingName: "Ticketmaster", formattedName: "Ticketmaster" },
  {
    incomingName: "Ubereats",
    formattedName: "UberEats",
  },
  {
    incomingName: "Ups",
    formattedName: "UPS",
  },
  {
    incomingName: "Venmo",
    formattedName: "Venmo",
  },
  {
    incomingName: "Wellsfargo",
    formattedName: "WellsFargo",
  },
  {
    incomingName: "Wetransfer",
    formattedName: "WeTransfer",
  },
  {
    incomingName: "Zelle",
    formattedName: "Zelle",
  },
  {
    incomingName: "Docusign",
    formattedName: "DocuSign",
  },
  {
    incomingName: "Fedex",
    formattedName: "FedEx",
  },
];
