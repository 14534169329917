import { DotLabelGreen, DotLabelOrange, DotLabelYellow } from "../..";
import { EyeIcon } from "../../../utils";

import { capitalizeFirstWords } from "../../../utils/helper-functions/capitalize";
import { truncate } from "../../../utils/helper-functions/truncate";

const ConfigureTestingCard = ({
  card,
  index,
  handleOpenModal,
  toggleSwitch,
}) => {
  return (
    <div className="border border-gray border-opacity-35 rounded-medium">
      <figure>
        <iframe
          srcDoc={card.content}
          title={card.title}
          className="h-9.5 w-full p-0 mb-1 rounded-extra-small bg-white"
          sandbox="allow-same-origin allow-scripts"
          scrolling="no"
        />
      </figure>
      <div className="px-0.5 pb-1">
        <div className="flex items-center justify-between">
          <div className="pt-0.25 text-h4">
            {capitalizeFirstWords(card.impersonation)}
          </div>
          <div className="flex items-center">
            <div
              className="mr-0.5 cursor-pointer h-1 w-1"
              onClick={() => handleOpenModal(card.content, card.title)}
            >
              <EyeIcon />
            </div>
            <div className="text-h6">
              {card.is_active ? "Active" : "Inactive"}
            </div>
            <div
              onClick={() => toggleSwitch(index)}
              className={`w-2.75 h-1.5 flex items-center rounded-full p-0.25 cursor-pointer transition-colors duration-300 ml-0.25 ${
                card.is_active ? "bg-green" : "bg-gray"
              }`}
            >
              <div
                className={`bg-white w-1 h-1 rounded-full shadow-md transform transition-transform duration-300 ${
                  card.is_active ? "translate-x-1.25" : "-translate-x-.125"
                }`}
              ></div>
            </div>
          </div>
        </div>
        <div className="text-h5 mt-0.25">
          {capitalizeFirstWords(card.title)}
        </div>

        <div className="flex justify-between items-start text-left mt-0.625">
          <div>
            <p className="tracking-wider uppercase text-gray text-h6">
              Difficulty
            </p>
            {card.fidelity === "easy" ? (
              <DotLabelGreen label={capitalizeFirstWords(card.fidelity)} />
            ) : card.fidelity === "medium" ? (
              <DotLabelYellow label={capitalizeFirstWords(card.fidelity)} />
            ) : (
              <DotLabelOrange label={capitalizeFirstWords(card.fidelity)} />
            )}
          </div>
          <div>
            <p className="tracking-wider uppercase text-gray text-h6">Motive</p>
            <p className="text-h6">
              {truncate(capitalizeFirstWords(card.motivational_factor), 15)}
            </p>
          </div>
          <div>
            <p className="tracking-wider uppercase text-gray text-h6">Method</p>
            <p className="text-h6">
              {truncate(capitalizeFirstWords(card.vector), 15)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigureTestingCard;
