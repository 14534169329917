import React from "react";

const CircleFilledGrayIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="8"
      viewBox="0 0 9 8"
      fill="none"
    >
      <circle cx="4.35645" cy="4" r="4" fill="#C2BFB8" />
    </svg>
  );
};

export default CircleFilledGrayIcon;
