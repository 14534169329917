import React, { useState } from "react";

import { postTokenUpload } from "../../../api";

import {
  ButtonContainedGreen,
  Divider,
  FileUpload,
  LoadingState,
} from "../../../components";

const Integrations = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleClickSubmit = async () => {
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    if (selectedFile) {
      const postTokenUploadResponse = await postTokenUpload(selectedFile);

      if (Object.keys(postTokenUploadResponse.error).length > 0) {
        console.error(postTokenUploadResponse.error.message);
        setErrorMessage("An error has occurred. Please try again.");
      } else {
        console.log(postTokenUploadResponse.result);
        setSelectedFile(null);
        setSuccessMessage("Your file has been uploaded successfully.");
      }
    } else {
      setErrorMessage("Please upload a file.");
    }

    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <div>
        <LoadingState message="Loading" />
      </div>
    );
  }

  return (
    <div>
      <div>
        <h2 className="h3">API Integrations</h2>
      </div>
      <div className="mt-2">
        <p className="body">
          To integrate your users into our platform, please upload your JSON
          file containing the necessary API keys. This file will allow us to
          securely connect your users and activate essential functionalities.
        </p>
      </div>
      <div className="mt-2">
        <FileUpload
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
        />
      </div>
      <div className="mt-2">
        <Divider />
      </div>
      <div
        className={`flex items-center w-full mt-2 ${successMessage || errorMessage ? "justify-between" : "justify-end"}`}
      >
        {successMessage && (
          <div>
            <p className="body text-green">{successMessage}</p>
          </div>
        )}
        {errorMessage && !successMessage && (
          <div>
            <p className="body text-red">{errorMessage}</p>
          </div>
        )}
        <ButtonContainedGreen onClick={handleClickSubmit}>
          Submit
        </ButtonContainedGreen>
      </div>
    </div>
  );
};

export default Integrations;
