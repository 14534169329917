import React from "react";
import { Link } from "react-router-dom";

import { Box, Button, Stack, Typography } from "@mui/material";

import "./configurations.css";
import { pagesSettings } from "../../../utils/constants/platform/configurations";

function Configurations() {
  return (
    <Box marginTop="60px">
      <Box>
        <Typography
          role="heading"
          aria-level="2"
          component="h2"
          className="dune-text-header-dashboard"
        >
          Settings
        </Typography>
      </Box>
      <Box>
        {pagesSettings.map((page, index) => (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            marginTop={index > 0 ? "40px" : "60px"}
            key={`configuration-page-${index}`}
          >
            <Typography component="p" marginRight="40px">
              {page.description}
            </Typography>
            <Button
              component={Link}
              variant="contained"
              className="dune-button-contained-white configurations-button-edit"
              to={page.link}
              aria-label={page.title}
              title={page.title}
            >
              Edit
            </Button>
          </Stack>
        ))}
      </Box>
    </Box>
  );
}

export default Configurations;
