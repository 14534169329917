import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  getComplianceFrameworks,
  getUsers,
  getUsersByComplianceFramework,
} from "../../../api";

import {
  Card,
  CardWithLoadingState,
  Link,
  Tabs,
  UsersTable,
} from "../../../components";

import { formatPercentage } from "../../../utils";

import { headers } from "../../../utils/constants/platform/compliance";

function Compliance({ isInitialLoading, email }) {
  // Dashboard
  const [compliantUsers, setCompliantUsers] = useState(null);
  const [currentComplianceFrameworks, setCurrentComplianceFrameworks] =
    useState([]);
  const [percentageCompliantUsers, setPercentageCompliantUsers] =
    useState(null);
  const [selectedComplianceFramework, setSelectedComplianceFramework] =
    useState({
      name: "",
    });
  const [users, setUsers] = useState(null);

  const { state } = useLocation();

  // State to cache compliance data
  const [complianceDataCache, setComplianceDataCache] = useState({});

  useEffect(() => {
    const loadComplianceFrameworks = async () => {
      const complianceFrameworksData = await getComplianceFrameworks();

      if (Object.keys(complianceFrameworksData.error).length > 0) {
        console.error(complianceFrameworksData.error.message);
      } else {
        const { complianceFrameworks } = complianceFrameworksData.result;

        setCurrentComplianceFrameworks(complianceFrameworks);
      }
    };

    const loadUsers = async () => {
      const usersData = await getUsers();

      if (Object.keys(usersData.error).length > 0) {
        console.error(usersData.error.message);
      } else {
        const users = usersData.result;

        console.log(users);

        if (currentComplianceFrameworks.length > 0) {
          let compliantUsers = 0;

          for (let i = 0; i < users.length; i++) {
            if (users[i].compliant) {
              compliantUsers++;
            }
          }

          let percentageCompliantUsers = compliantUsers / users.length;

          if (email === "david@dune.demo") {
            // Demo account
            compliantUsers = 9679;
            percentageCompliantUsers = 0.92;
          }

          setCompliantUsers(compliantUsers);
          setPercentageCompliantUsers(percentageCompliantUsers);
          setUsers(users);

          setComplianceDataCache({
            All: {
              compliantUsers,
              percentageCompliantUsers,
              users,
            },
          });
        }
      }
    };

    const updateComponent = async () => {
      if (email === "david@dune.demo") {
        setCurrentComplianceFrameworks([
          {
            name: "SOC 2 Type 2",
          },
          {
            name: "HIPAA",
          },
          {
            name: "ISO27001",
          },
        ]);
      } else {
        await loadComplianceFrameworks();
      }

      if (currentComplianceFrameworks.length > 0) {
        // The current compliance frameworks does have at least 1 framework.
        await loadUsers();

        setSelectedComplianceFramework({
          name: "All", // All is default.
        });
      } else {
        setUsers([]);
        setCompliantUsers(0);
        setPercentageCompliantUsers(0);
      }
    };

    if (!isInitialLoading && email) {
      if (state && state.complianceFramework) {
        // Filter by compliance framework if it exists in state.
        setSelectedComplianceFramework(state.complianceFramework);
      }

      updateComponent();
    }
  }, [isInitialLoading, email, state, currentComplianceFrameworks.length]);

  // Update compliance framework data when switching tabs.
  useEffect(() => {
    const loadUsersByComplianceFramework = async () => {
      // Check if data for this compliance framework is already cached.
      if (complianceDataCache[selectedComplianceFramework.name]) {
        const { compliantUsers, percentageCompliantUsers, users } =
          complianceDataCache[selectedComplianceFramework.name];

        setCompliantUsers(compliantUsers);
        setPercentageCompliantUsers(percentageCompliantUsers);
        setUsers(users);

        return; // Return early as we have cached data.
      }

      if (email === "david@dune.demo") {
        let updatedCompliantUsers = 0;
        let updatedPercentageCompliantUsers = 0;

        if (selectedComplianceFramework.name === "SOC 2 Type 2") {
          updatedCompliantUsers = 9644;
          updatedPercentageCompliantUsers = 0.92;
        } else if (selectedComplianceFramework.name === "HIPAA") {
          updatedCompliantUsers = 9854;
          updatedPercentageCompliantUsers = 0.94;
        } else if (selectedComplianceFramework.name === "ISO27001") {
          updatedCompliantUsers = 9540;
          updatedPercentageCompliantUsers = 0.91;
        }

        const shuffledUsers = [...users].sort(() => Math.random() - 0.5);
        const updatedUsers = shuffledUsers.map((user) => ({
          ...user,
          compliant: Math.random() < updatedPercentageCompliantUsers,
        }));

        setCompliantUsers(updatedCompliantUsers);
        setPercentageCompliantUsers(updatedPercentageCompliantUsers);
        setUsers(updatedUsers);

        const updatedComplianceDataCache = { ...complianceDataCache };
        updatedComplianceDataCache[selectedComplianceFramework.name] = {
          compliantUsers: updatedCompliantUsers,
          percentageCompliantUsers,
          updatedPercentageCompliantUsers,
          users,
        };
      } else if (selectedComplianceFramework.name !== "All") {
        // If the selected compliance framework is "All", make sure to use the cache once it is loaded from loadUsers().
        const usersData = await getUsersByComplianceFramework(
          selectedComplianceFramework,
        );

        if (Object.keys(usersData.error).length > 0) {
          console.error(usersData.error.message);
        } else {
          const { compliantUsers, percentageCompliantUsers, users } =
            usersData.result;

          setCompliantUsers(compliantUsers);
          setPercentageCompliantUsers(percentageCompliantUsers);
          setUsers(users);

          const updatedComplianceDataCache = { ...complianceDataCache };
          updatedComplianceDataCache[selectedComplianceFramework.name] = {
            compliantUsers,
            percentageCompliantUsers,
            users,
          };

          setComplianceDataCache(updatedComplianceDataCache);
        }
      }
    };

    if (selectedComplianceFramework && selectedComplianceFramework.name) {
      loadUsersByComplianceFramework();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedComplianceFramework]);

  return (
    <div className="flex flex-col">
      <div className="mt-2">
        <Card>
          <p className="body">
            If you need to update your compliance standards, email{" "}
            <Link
              href="mailto:support@dune.security"
              title="Email support@dune.security."
            >
              support@dune.security
            </Link>
            .
          </p>
        </Card>
      </div>
      <div className="mt-4">
        <CardWithLoadingState loadingVariable={users}>
          {currentComplianceFrameworks.length === 0 && (
            <div>
              <p className="h4">No Compliance selected</p>
            </div>
          )}
          {currentComplianceFrameworks.length > 0 && (
            <>
              <div>
                <Tabs
                  data={[{ name: "All" }, ...currentComplianceFrameworks]}
                  selectedTab={selectedComplianceFramework}
                  setSelectedTab={setSelectedComplianceFramework}
                />
              </div>
              <div className="flex justify-between md:max-w-[548px] w-full mt-2">
                <div>
                  <p className="h2">{compliantUsers.toLocaleString("en-US")}</p>
                  <p className="body mt-0.75">
                    Compliant User
                    {compliantUsers === null ||
                      (compliantUsers !== null && compliantUsers !== 1 && "s")}
                  </p>
                </div>
                <div className="ml-1">
                  <p className="h2">
                    {formatPercentage(percentageCompliantUsers)}
                  </p>
                  <p className="body mt-0.75">of total users compliant</p>
                </div>
              </div>
              <div className="mt-2">
                <UsersTable
                  data={users}
                  headers={headers}
                  fixedTotal={email === "david@dune.demo" ? 10483 : null}
                />
              </div>
            </>
          )}
        </CardWithLoadingState>
      </div>
    </div>
  );
}

export default Compliance;
