import React from "react";

const BlastRadiusAnalysisLogo = () => {
  return (
    <svg
      width="27"
      height="28"
      viewBox="0 0 27 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_232_22886)">
        <path
          d="M13.992 19.556L13.992 11.5985L13.1769 11.5985L13.1769 19.556L13.992 19.556Z"
          fill="#00FF82"
        />
        <path
          d="M3.93258 19.5569L3.93258 15.1693L23.2366 15.1693L23.2366 19.5569L22.4214 19.5569L22.4214 15.9986L4.7477 15.9986L4.7477 19.5569L3.93258 19.5569Z"
          fill="#00FF82"
        />
        <path
          d="M16.554 27.1934L16.554 21.153L10.6171 21.153L10.6171 27.1934L16.554 27.1934Z"
          fill="#00FF82"
        />
        <path
          d="M26.6279 27.1934L26.6279 21.153L20.691 21.153L20.691 27.1934L26.6279 27.1934Z"
          fill="#00FF82"
        />
        <path
          d="M6.48538 27.1938L6.48538 21.1534L0.548488 21.1534L0.548488 27.1938L6.48538 27.1938Z"
          fill="#00FF82"
        />
        <path
          d="M18.1798 10.0022L18.1798 0.655609L8.99349 0.655609L8.99349 10.0022L18.1798 10.0022Z"
          fill="#00FF82"
        />
      </g>
      <defs>
        <clipPath id="clip0_232_22886">
          <rect
            width="26.5373"
            height="26.0825"
            fill="white"
            transform="translate(26.6265 0.655212) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BlastRadiusAnalysisLogo;
