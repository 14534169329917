import React from "react";

const Avatar = ({ children }) => {
  return (
    <div className="description flex justify-center items-center rounded-full w-[24px] h-[24px] p-[5px] text-white text-[0.625rem] bg-gray-avatar border-[0.5px] border-gray-dark">
      {children}
    </div>
  );
};

export default Avatar;
