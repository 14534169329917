import React from "react";

import { CardWithLoadingState, List } from "../../../../common";

function CardAreasToImprove({ areasToImprove }) {
  return (
    <CardWithLoadingState loadingVariable={areasToImprove}>
      <div>
        <div className="text-center card-header">High Risk Areas</div>
      </div>
      <div className="mt-1.25">
        {areasToImprove && (
          <>
            {Array.isArray(areasToImprove) && areasToImprove.length > 0 ? (
              <List data={areasToImprove} keyName="name" />
            ) : (
              <p className="description">No areas to improve</p>
            )}
          </>
        )}
      </div>
    </CardWithLoadingState>
  );
}

export default CardAreasToImprove;
