import React from "react";
import { useNavigate } from "react-router-dom";

import {
  ButtonOutlinedGreen,
  CardWithLoadingState,
  UsersTable,
} from "../../..";

import { headers } from "../../../../../utils/constants/platform/card-risk-employees";

function CardRiskEmployees({ users, enableDescendingOrder }) {
  const navigate = useNavigate();

  const handleClickViewMore = () => {
    navigate("/insights/users/search/", {
      state: {
        defaultOrder: enableDescendingOrder ? "desc" : "asc",
        defaultOrderBy: "riskScore",
      },
    });
  };

  return (
    <CardWithLoadingState loadingVariable={users} minHeight="317px">
      <UsersTable
        data={users}
        headers={headers}
        rowsPerPage={5}
        defaultOrder={enableDescendingOrder ? "desc" : "asc"}
        defaultOrderBy="riskScore"
        disablePagination
        disableSearchBar
        customFooter={
          <div className="flex justify-end mt-1">
            <ButtonOutlinedGreen onClick={handleClickViewMore} isSmall>
              View all
            </ButtonOutlinedGreen>
          </div>
        }
      />
    </CardWithLoadingState>
  );
}

export default CardRiskEmployees;
