import React from "react";

const QuantifyLogo = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0906 2.91537H5.04534C4.02036 2.91537 3.18173 3.76862 3.18173 4.81148V18.0842C3.18173 19.1271 4.02036 19.9804 5.04534 19.9804H18.0906C19.1156 19.9804 19.9542 19.1271 19.9542 18.0842V4.81148C19.9542 3.76862 19.1156 2.91537 18.0906 2.91537ZM8.77256 16.1881H6.90895V9.55176H8.77256V16.1881ZM12.4998 16.1881H10.6362V6.70759H12.4998V16.1881ZM16.227 16.1881H14.3634V12.3959H16.227V16.1881Z"
        fill="#00FF82"
      />
    </svg>
  );
};

export default QuantifyLogo;
