import React from "react";

import { LoadingSpinner } from "..";

const LoadingState = ({ message = "Data visualization loading." }) => {
  return (
    <div className="flex flex-col items-center justify-center flex-1 text-center">
      <div className="flex items-center justify-center">
        <LoadingSpinner />
      </div>
      <div className="mt-0.625">
        <p className="description">{message}</p>
      </div>
    </div>
  );
};

export default LoadingState;
