import React from "react";

import { CardWithLoadingState } from "../../..";

function CardProfileDepartment({ totalUsers, description }) {
  return (
    <CardWithLoadingState
      loadingVariable={totalUsers}
      minHeight={totalUsers === null ? "120px" : 0}
    >
      <div className="flex flex-row justify-between">
        {totalUsers !== null && totalUsers !== undefined && totalUsers > 0 && (
          <div>
            <div>
              <p className="card-subheader">Employees</p>
            </div>
            <div className="mt-0.25">
              <p className="font-medium body">
                {totalUsers.toLocaleString("en-US")}
              </p>
            </div>
          </div>
        )}
      </div>
      {description && (
        <div
          className={
            totalUsers !== null && totalUsers !== undefined && totalUsers > 0
              ? "mt-1.25"
              : ""
          }
        >
          <p className="body">{description}</p>
        </div>
      )}
    </CardWithLoadingState>
  );
}

export default CardProfileDepartment;
