const EcosystemLinearSVG = () => {
  const position = {
    x1: 697,
    y1: 0,
    x2: 697,
    y2: 195,
  };

  if (position.x1 === position.x2) {
    position.x1 -= 0.0001;
  }

  const calculateAngle = (x1, y1, x2, y2) => {
    return (Math.atan2(y2 - y1, x2 - x1) * 180) / Math.PI;
  };

  const extendLine = (x1, y1, x2, y2, extensionLength) => {
    const angle = Math.atan2(y2 - y1, x2 - x1);
    const newX2 = x2 + extensionLength * Math.cos(angle);
    const newY2 = y2 + extensionLength * Math.sin(angle);
    return { x1, y1, x2: newX2, y2: newY2 };
  };

  const extendedPosition1 = extendLine(
    position.x1,
    position.y1,
    position.x2,
    position.y2,
    20,
  );
  const extendedPosition2 = extendLine(1386.04, -2.41894, 764.326, 197.395, 40);
  const extendedPosition3 = extendLine(0.981425, -5.42348, 626.692, 199.4, 40);
  const extendedPosition4 = extendLine(1041.13, -5.61992, 738.997, 195.397, 40);
  const extendedPosition5 = extendLine(349.487, -5.41992, 649.488, 195.397, 40);

  const midPoint1 = {
    x: (extendedPosition1.x1 + extendedPosition1.x2) / 2,
    y: (extendedPosition1.y1 + extendedPosition1.y2) / 2,
  };
  const angle1 = calculateAngle(
    extendedPosition1.x1 - 160,
    extendedPosition1.y1,
    extendedPosition1.x2,
    extendedPosition1.y2,
  );

  const midPoint2 = { x: (1386.04 + 764.326) / 2, y: (-2.41894 + 195) / 2 };
  const angle2 = calculateAngle(806.04, 2.41894, 764.326, 105.395);

  const midPoint3 = { x: (0.981425 + 626.692) / 2, y: (-5.42348 + 195) / 2 };
  const angle3 = calculateAngle(0.981425, -5.42348, 200026.692, 205.4);

  const midPoint4 = { x: (1041.13 + 738.997) / 2, y: (-5.61992 + 200) / 2 };
  const angle4 = calculateAngle(800.13, -5.61992, 738.997, 205.397);

  const midPoint5 = { x: (349.487 + 649.488) / 2, y: (-5.41992 + 195) / 2 };
  const angle5 = calculateAngle(449.487, -5.41992, 10249.488, 205.397);

  return (
    <svg
      role="presentation"
      width="1187"
      height="211"
      viewBox="0 0 1387 211"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <defs>
        <linearGradient
          id="base-line-gradient"
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
        >
          <stop offset="0%" stopColor="#0A3D23" />
          <stop offset="100%" stopColor="transparent" />
        </linearGradient>
        <linearGradient
          id="moving-green-gradient"
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
        >
          <stop offset="0%" stopColor="transparent" />
          <stop offset="40%" stopColor="transparent">
            <animate
              attributeName="offset"
              values="0;1"
              dur="2s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="50%" stopColor="#00FF82">
            <animate
              attributeName="offset"
              values="0.1;1.1"
              dur="2s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="60%" stopColor="transparent">
            <animate
              attributeName="offset"
              values="0.2;1.2"
              dur="2s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="100%" stopColor="transparent" />
        </linearGradient>
      </defs> */}
      <defs>
        <linearGradient
          id="base-line-gradient"
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
        >
          <stop offset="30%" stopColor="#0A3D23" />
          <stop offset="70%" stopColor="#042413" />
          <stop offset="90%" stopColor="#011209" />
          <stop offset="100%" stopColor="#010a05" />
        </linearGradient>

        <linearGradient
          id="moving-green-gradient"
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
        >
          <stop offset="0%" stopColor="transparent" />
          <stop offset="40%" stopColor="transparent">
            <animate
              attributeName="offset"
              values="0;1"
              dur="2s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="50%" stopColor="#00FF82">
            <animate
              attributeName="offset"
              values="0.1;1.1"
              dur="2s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="60%" stopColor="transparent">
            <animate
              attributeName="offset"
              values="0.2;1.2"
              dur="2s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="100%" stopColor="transparent" />
        </linearGradient>
      </defs>

      {/* First line */}
      <path
        d={`M${extendedPosition1.x1} ${extendedPosition1.y1}L${extendedPosition1.x2} ${extendedPosition1.y2}`}
        stroke="url(#base-line-gradient)"
        strokeWidth="4.59846"
        fill="none"
      />
      <path
        d={`M${extendedPosition1.x1} ${extendedPosition1.y1}L${extendedPosition1.x2} ${extendedPosition1.y2}`}
        stroke="url(#moving-green-gradient)"
        strokeWidth="4.59846"
        fill="none"
      />
      <g
        transform={`translate(${midPoint1.x}, ${midPoint1.y}) rotate(${angle1}, 5, 0)`}
      >
        {/* Arrow */}
        <svg
          width="15"
          height="17"
          viewBox="0 0 15 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.4485 1.77342C10.38 1.00567 10.9392 0.296251 11.7015 0.183981C12.4684 0.0710237 13.1514 0.604887 13.2269 1.3764L14.0847 10.1376C14.2478 11.8034 14.3293 12.6363 14.0716 13.3238C13.8449 13.9288 13.4398 14.4556 12.9132 14.8302C12.3146 15.2561 11.4881 15.3918 9.83518 15.6634L2.68242 16.8383C1.78656 16.9854 0.979885 16.3661 0.891492 15.4633C0.803475 14.5643 1.46182 13.7308 2.35633 13.6087L6.57835 13.0322C8.26385 12.802 9.10661 12.687 9.72105 12.2688C10.2615 11.9009 10.6808 11.3745 10.9187 10.7652C11.1891 10.0724 11.1134 9.22443 10.9621 7.52844L10.4485 1.77342Z"
            fill="#00FF82"
          />
        </svg>
      </g>
      {/* Second line */}
      <path
        d={`M1386.04 -2.41894L${extendedPosition2.x2} ${extendedPosition2.y2}`}
        stroke="url(#base-line-gradient)"
        strokeWidth="4.59846"
        fill="none"
      />
      <path
        d={`M1386.04 -2.41894L${extendedPosition2.x2} ${extendedPosition2.y2}`}
        stroke="url(#moving-green-gradient)"
        strokeWidth="4.59846"
        fill="none"
      />
      <g
        transform={`translate(${midPoint2.x}, ${midPoint2.y}) rotate(${angle2}, 0, 0)`}
      >
        <svg
          width="15"
          height="17"
          viewBox="0 0 15 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.4485 1.77342C10.38 1.00567 10.9392 0.296251 11.7015 0.183981C12.4684 0.0710237 13.1514 0.604887 13.2269 1.3764L14.0847 10.1376C14.2478 11.8034 14.3293 12.6363 14.0716 13.3238C13.8449 13.9288 13.4398 14.4556 12.9132 14.8302C12.3146 15.2561 11.4881 15.3918 9.83518 15.6634L2.68242 16.8383C1.78656 16.9854 0.979885 16.3661 0.891492 15.4633C0.803475 14.5643 1.46182 13.7308 2.35633 13.6087L6.57835 13.0322C8.26385 12.802 9.10661 12.687 9.72105 12.2688C10.2615 11.9009 10.6808 11.3745 10.9187 10.7652C11.1891 10.0724 11.1134 9.22443 10.9621 7.52844L10.4485 1.77342Z"
            fill="#00FF82"
          />
        </svg>
      </g>
      {/* Third line */}
      <path
        d={`M0.981425 -5.42348L${extendedPosition3.x2} ${extendedPosition3.y2}`}
        stroke="url(#base-line-gradient)"
        strokeWidth="4.59846"
        fill="none"
      />
      <path
        d={`M0.981425 -5.42348L${extendedPosition3.x2} ${extendedPosition3.y2}`}
        stroke="url(#moving-green-gradient)"
        strokeWidth="4.59846"
        fill="none"
      />
      <g
        transform={`translate(${midPoint3.x}, ${midPoint3.y}) rotate(${angle3}, 0, 0)`}
      >
        <svg
          width="15"
          height="17"
          viewBox="0 0 15 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.4485 1.77342C10.38 1.00567 10.9392 0.296251 11.7015 0.183981C12.4684 0.0710237 13.1514 0.604887 13.2269 1.3764L14.0847 10.1376C14.2478 11.8034 14.3293 12.6363 14.0716 13.3238C13.8449 13.9288 13.4398 14.4556 12.9132 14.8302C12.3146 15.2561 11.4881 15.3918 9.83518 15.6634L2.68242 16.8383C1.78656 16.9854 0.979885 16.3661 0.891492 15.4633C0.803475 14.5643 1.46182 13.7308 2.35633 13.6087L6.57835 13.0322C8.26385 12.802 9.10661 12.687 9.72105 12.2688C10.2615 11.9009 10.6808 11.3745 10.9187 10.7652C11.1891 10.0724 11.1134 9.22443 10.9621 7.52844L10.4485 1.77342Z"
            fill="#00FF82"
          />
        </svg>
      </g>

      {/* Fourth line */}
      <path
        d={`M1041.13 -5.61992L${extendedPosition4.x2} ${extendedPosition4.y2}`}
        stroke="url(#base-line-gradient)"
        strokeWidth="4.59846"
        fill="none"
      />
      <path
        d={`M1041.13 -5.61992L${extendedPosition4.x2} ${extendedPosition4.y2}`}
        stroke="url(#moving-green-gradient)"
        strokeWidth="4.59846"
        fill="none"
      />
      <g
        transform={`translate(${midPoint4.x}, ${midPoint4.y}) rotate(${angle4}, 0, 0)`}
      >
        <svg
          width="15"
          height="17"
          viewBox="0 0 15 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.4485 1.77342C10.38 1.00567 10.9392 0.296251 11.7015 0.183981C12.4684 0.0710237 13.1514 0.604887 13.2269 1.3764L14.0847 10.1376C14.2478 11.8034 14.3293 12.6363 14.0716 13.3238C13.8449 13.9288 13.4398 14.4556 12.9132 14.8302C12.3146 15.2561 11.4881 15.3918 9.83518 15.6634L2.68242 16.8383C1.78656 16.9854 0.979885 16.3661 0.891492 15.4633C0.803475 14.5643 1.46182 13.7308 2.35633 13.6087L6.57835 13.0322C8.26385 12.802 9.10661 12.687 9.72105 12.2688C10.2615 11.9009 10.6808 11.3745 10.9187 10.7652C11.1891 10.0724 11.1134 9.22443 10.9621 7.52844L10.4485 1.77342Z"
            fill="#00FF82"
          />
        </svg>
      </g>
      {/* Fifth line */}
      <path
        d={`M349.487 -5.41992L${extendedPosition5.x2} ${extendedPosition5.y2}`}
        stroke="url(#base-line-gradient)"
        strokeWidth="4.59846"
        fill="none"
      />
      <path
        d={`M349.487 -5.41992L${extendedPosition5.x2} ${extendedPosition5.y2}`}
        stroke="url(#moving-green-gradient)"
        strokeWidth="4.59846"
        fill="none"
      />
      <g
        transform={`translate(${midPoint5.x}, ${midPoint5.y}) rotate(${angle5}, 200, 0)`}
      >
        <svg
          width="15"
          height="17"
          viewBox="0 0 15 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.4485 1.77342C10.38 1.00567 10.9392 0.296251 11.7015 0.183981C12.4684 0.0710237 13.1514 0.604887 13.2269 1.3764L14.0847 10.1376C14.2478 11.8034 14.3293 12.6363 14.0716 13.3238C13.8449 13.9288 13.4398 14.4556 12.9132 14.8302C12.3146 15.2561 11.4881 15.3918 9.83518 15.6634L2.68242 16.8383C1.78656 16.9854 0.979885 16.3661 0.891492 15.4633C0.803475 14.5643 1.46182 13.7308 2.35633 13.6087L6.57835 13.0322C8.26385 12.802 9.10661 12.687 9.72105 12.2688C10.2615 11.9009 10.6808 11.3745 10.9187 10.7652C11.1891 10.0724 11.1134 9.22443 10.9621 7.52844L10.4485 1.77342Z"
            fill="#00FF82"
          />
        </svg>
      </g>
    </svg>
  );
};

export default EcosystemLinearSVG;
