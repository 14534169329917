import React from "react";

import { CardWithLoadingState, UsersTable } from "../../../../common";

const headers = [
  {
    id: "trainingName",
    label: "Title",
    isSortable: true,
    align: "left",
  },
  {
    id: "status",
    label: "Completion Status",
    isSortable: true,
    align: "left",
    width: "300px",
  },
];

function CardRecentTrainingActivity({ learningModulesActivity }) {
  return (
    <CardWithLoadingState loadingVariable={learningModulesActivity}>
      {learningModulesActivity && (
        <UsersTable
          data={learningModulesActivity}
          headers={headers}
          defaultOrderBy="trainingName"
          disableSearchBar
          isForUserTraining
          rowsPerPage={5}
        />
      )}
    </CardWithLoadingState>
  );
}

export default CardRecentTrainingActivity;
