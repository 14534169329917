import React from "react";

const CategoryList = ({
  categories,
  showAllAssetTypes,
  didFetchAllPages,
  handleSubCategoryCheck,
  expandList,
}) => {
  return (
    <div>
      {categories?.map((category, categoryIndex) => (
        <div key={category.categoryName} className="mb-1">
          <div className="flex items-center mb-0.75 text-gray uppercase tracking-custom-1.08 text-h6">
            {category.categoryName}
          </div>

          {category.subCategories.length > 0 ? (
            <div className="ml-0.5">
              {category.subCategories
                .slice(0, showAllAssetTypes ? category.subCategories.length : 5)
                .map((subCategory, subCategoryIndex) => (
                  <div
                    key={subCategory.name}
                    className="flex items-center mb-0.5"
                  >
                    <input
                      type="checkbox"
                      disabled={!didFetchAllPages}
                      checked={subCategory.isChecked}
                      onChange={() =>
                        handleSubCategoryCheck(categoryIndex, subCategoryIndex)
                      }
                      className={`mr-0.5 appearance-none border rounded border-gray h-1 w-1 text-black checked:bg-green checked:border-none focus:outline-none checked:shadow-[0px_0px_18px_0px_#028334]`}
                    />
                    <label className="text-gray text-h5">
                      {subCategory.displayName}
                    </label>
                  </div>
                ))}
              {category.subCategories.length > 5 && (
                <button
                  className="cursor-pointer text-h6 text-green"
                  onClick={expandList}
                >
                  {showAllAssetTypes ? "Show Less" : "Show More"}
                </button>
              )}
            </div>
          ) : (
            <div>No Assets available</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default CategoryList;
