import React from "react";

const AnomalousRiskyActivityLogo = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8223 10.4382V14.5145M3.70998 18.1842C2.76864 19.8147 3.94586 21.8517 5.82746 21.8517H21.8172C23.6977 21.8517 24.8749 19.8147 23.9347 18.1842L15.9409 4.32712C14.9996 2.69662 12.6451 2.69662 11.7038 4.32712L3.70998 18.1842ZM13.8223 17.7755H13.8299V17.7842H13.8223V17.7755Z"
        stroke="#00FF82"
        strokeWidth="1.22287"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AnomalousRiskyActivityLogo;
