//! New snippet

// Input: "YYYY-MM-DD" (eg. "2021-08-01")
// Output: "MM/DD/YYYY" (eg. "08/01/2021")
export const formatDateTwo = (date) => {
  const givenDate = new Date(date);
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  return givenDate.toLocaleDateString("en-US", options);
};

/* 
   This function has no duplicates.
   Input: "2021-08-01"
   Output: "08/01/2021"
*/

// Input: "YYYY-MM-DD" (eg. "2021-08-01")
// Output: "MM-DD-YYYY" (eg. "08-01-2021")
export const formatDateForValue = (date) => {
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
};

/* 
   This function has no duplicates.
   Input: "2021-08-01"
   Output: "08-01-2021"
*/

// Input: YYYY-MM-DDTHH:MM:SSZ (eg. "2021-08-01T00:00:00Z") or "YYYY-MM-DD" (eg. "2021-08-01")
// Output: "Month DD, YYYY" (eg. "August 1, 2021")
export const formatDateFive = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

/* 
   This function was kept because it handles both "YYYY-MM-DD" and ISO date strings. 
   It is a duplicate of:
   - formatDateOne
   - formatDateFive
   - formatDateFive
   - formatDateFive

   Input: "2021-08-01"
   Output: "August 1, 2021"

   Input: "2021-08-01T00:00:00Z"
   Output: "August 1, 2021"
*/
