import React from "react";

const UserSpecificTestingLogo = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3462 24.2127L14.8701 18.6994M14.8701 18.6994L12.1417 21.118L12.7602 10.8241L18.442 19.4299L14.8701 18.6994ZM6.69801 18.4092C5.65528 17.3663 4.88583 16.0824 4.45782 14.6711C4.0298 13.2599 3.95644 11.7648 4.24423 10.3184C4.53202 8.87206 5.17207 7.51896 6.1077 6.37902C7.04332 5.23908 8.24564 4.34747 9.60815 3.78318C10.9707 3.21888 12.4513 2.99932 13.9189 3.14393C15.3866 3.28854 16.7959 3.79287 18.0221 4.61225C19.2482 5.43162 20.2534 6.54074 20.9486 7.84137C21.6437 9.142 22.0074 10.594 22.0073 12.0687M9.00463 16.1037C8.34093 15.4401 7.85114 14.6231 7.57865 13.725C7.30616 12.8269 7.25937 11.8754 7.44244 10.9549C7.6255 10.0344 8.03277 9.17327 8.62816 8.44778C9.22355 7.72228 9.98869 7.15482 10.8558 6.79568C11.7229 6.43654 12.6652 6.29679 13.5992 6.38882C14.5332 6.48085 15.4301 6.80182 16.2104 7.32329C16.9907 7.84476 17.6304 8.55064 18.0727 9.37839C18.5151 10.2061 18.7464 11.1302 18.7463 12.0687"
        stroke="#00FF82"
        strokeWidth="1.22287"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserSpecificTestingLogo;
