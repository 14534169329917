import React from "react";

import { DropDownArrowIcon } from "../../../utils/icons";

const Select = ({ value, onChange, children }) => {
  return (
    <div className="flex items-center cursor-pointer rounded-small border border-border-color py-0.5 px-1">
      <select
        className="w-full normal-case appearance-none cursor-pointer description text-ellipsis bg-gray-select pr-0.5 focus:ring-0 focus:outline-none focus:border-green"
        value={value}
        onChange={onChange}
      >
        {children}
      </select>
      <DropDownArrowIcon />
    </div>
  );
};

export default Select;
