import React from "react";

const NodesOutlinedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M7.21705 10.907C6.97492 10.4713 6.59496 10.1283 6.13682 9.93196C5.67869 9.73558 5.16831 9.69689 4.68581 9.82196C4.2033 9.94703 3.77598 10.2288 3.47093 10.623C3.16588 11.0172 3.00037 11.5015 3.00037 12C3.00037 12.4984 3.16588 12.9828 3.47093 13.377C3.77598 13.7712 4.2033 14.0529 4.68581 14.178C5.16831 14.3031 5.67869 14.2644 6.13682 14.068C6.59496 13.8716 6.97492 13.5287 7.21705 13.093M7.21705 10.907C7.39705 11.231 7.50005 11.603 7.50005 12C7.50005 12.397 7.39705 12.77 7.21705 13.093M7.21705 10.907L16.7831 5.59298M7.21705 13.093L16.7831 18.407M16.7831 5.59298C16.9233 5.85712 17.1148 6.09064 17.3464 6.27991C17.5779 6.46917 17.8449 6.61037 18.1317 6.69525C18.4184 6.78014 18.7192 6.807 19.0165 6.77427C19.3138 6.74154 19.6015 6.64988 19.863 6.50464C20.1244 6.3594 20.3543 6.1635 20.5391 5.92839C20.7239 5.69328 20.86 5.42369 20.9395 5.13536C21.0189 4.84704 21.0401 4.54577 21.0017 4.24917C20.9633 3.95258 20.8662 3.66661 20.7161 3.40798C20.4201 2.89832 19.936 2.52511 19.3679 2.36859C18.7997 2.21207 18.1928 2.28472 17.6776 2.57093C17.1624 2.85714 16.7801 3.33406 16.6128 3.89917C16.4455 4.46428 16.5067 5.07246 16.7831 5.59298ZM16.7831 18.407C16.6395 18.6654 16.5483 18.9495 16.5145 19.2431C16.4808 19.5367 16.5052 19.8341 16.5865 20.1183C16.6677 20.4025 16.804 20.668 16.9878 20.8994C17.1716 21.1309 17.3992 21.3239 17.6576 21.4675C17.9159 21.611 18.2 21.7023 18.4937 21.736C18.7873 21.7697 19.0847 21.7453 19.3689 21.6641C19.6531 21.5829 19.9185 21.4465 20.15 21.2627C20.3815 21.0789 20.5745 20.8514 20.7181 20.593C21.0079 20.0712 21.0787 19.4556 20.9147 18.8816C20.7507 18.3077 20.3654 17.8224 19.8436 17.5325C19.3217 17.2426 18.7061 17.1719 18.1322 17.3359C17.5582 17.4999 17.0729 17.8852 16.7831 18.407Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NodesOutlinedIcon;
