import { getResponseGet } from "../utils";

const getTestFrequencies = async () => {
    let testFrequenciesData = {
        result: {
            testFrequencies: {}
        },
        error: {}
    };

    try {
        const response = await getResponseGet("/post-user-guardrails/");

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const data = response.data;

        if (data.test_frequency_groups !== undefined && data.test_frequency_groups !== null
            && data.test_frequency_groups.frequency_data !== undefined && data.test_frequency_groups.frequency_data !== null) {
                testFrequenciesData.result.testFrequencies = data.test_frequency_groups.frequency_data;
        }
    } catch (error) {
        testFrequenciesData.error = error;
    }

    return testFrequenciesData;
};

export default getTestFrequencies;