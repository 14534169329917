import React from "react";

const NetskapeIcon = () => {
  return (
    <svg
      width="68"
      height="15"
      viewBox="0 0 46 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.23321 3.85678C4.17144 4.00342 4.08222 4.15006 3.95182 4.27575C3.6773 4.55506 3.33414 4.66678 2.97726 4.68075L4.31556 6.04238C4.33615 5.67928 4.4391 5.33014 4.71362 5.05083C4.83716 4.92514 4.98128 4.83437 5.1254 4.76454C4.71362 4.61092 4.38419 4.27575 4.23321 3.85678Z"
        fill="#F6F6F6"
      />
      <path
        d="M3.93791 6.93601C4.44578 6.41929 4.44578 5.58136 3.93791 5.06464C3.43005 4.54791 2.60648 4.54791 2.09861 5.06464C1.59075 5.58136 1.59075 6.41929 2.09861 6.93601C2.60648 7.45274 3.43005 7.45274 3.93791 6.93601Z"
        fill="#F6F6F6"
      />
      <path
        d="M6.57357 2.39066C7.08143 2.90738 7.08143 3.74531 6.57357 4.26203C6.0657 4.77876 5.24213 4.77876 4.73426 4.26203C4.2264 3.74531 4.2264 2.90738 4.73426 2.39066C5.24213 1.87393 6.0657 1.87393 6.57357 2.39066Z"
        fill="#F6F6F6"
      />
      <path
        d="M11.8443 2.39066C11.3365 1.87393 10.5129 1.87393 10.005 2.39066C9.75796 2.64203 9.63442 2.96324 9.62756 3.28445C9.60697 3.64755 9.50403 3.99669 9.2295 4.276C9.10597 4.40169 8.96184 4.49945 8.81772 4.56229C9.23637 4.71591 9.56579 5.05109 9.71678 5.47703C9.77855 5.32341 9.86777 5.18376 9.99817 5.05807C10.2727 4.77876 10.6158 4.66703 10.9727 4.65307C11.2953 4.64609 11.611 4.51341 11.8512 4.26902C12.3522 3.74531 12.3522 2.90738 11.8443 2.39066ZM11.3914 3.80117C11.2678 3.92686 11.0963 3.99669 10.9247 3.99669C10.7462 3.99669 10.5815 3.92686 10.458 3.80117C10.1972 3.53583 10.1972 3.10988 10.458 2.84453C10.5815 2.71884 10.7531 2.64902 10.9247 2.64902C11.1031 2.64902 11.2678 2.71884 11.3914 2.84453C11.5149 2.97022 11.5835 3.14479 11.5835 3.31936C11.5904 3.5079 11.5218 3.67548 11.3914 3.80117Z"
        fill="#F6F6F6"
      />
      <path
        d="M9.20937 5.07187C9.71724 5.58859 9.71724 6.42652 9.20937 6.94325C8.7015 7.45997 7.87793 7.45997 7.37007 6.94325C6.8622 6.42652 6.8622 5.58859 7.37007 5.07187C7.87793 4.55515 8.7015 4.55515 9.20937 5.07187Z"
        fill="#F6F6F6"
      />
      <path
        d="M7.35586 4.27594C7.08134 3.99663 6.97153 3.6475 6.9578 3.28439L5.61264 4.64603C5.96952 4.66698 6.31267 4.77172 6.5872 5.05103C6.86172 5.33034 6.97153 5.67948 6.98525 6.04258L8.32355 4.68094C7.97353 4.66698 7.63038 4.55525 7.35586 4.27594Z"
        fill="#F6F6F6"
      />
      <path
        d="M23.5121 7.34834C22.8533 7.34834 22.4415 6.93636 22.4415 6.27998V2.25093C22.4415 2.09033 22.5581 1.97162 22.716 1.97162C22.8738 1.97162 22.9905 2.09731 22.9905 2.25093V3.61955H23.9582C24.1023 3.61955 24.2259 3.74524 24.2259 3.89188C24.2259 4.03153 24.1023 4.15722 23.9582 4.15722H22.9905V6.24506C22.9905 6.62912 23.1758 6.81067 23.567 6.81067C23.7111 6.81067 23.8004 6.78274 23.8621 6.76179C23.8964 6.74782 23.9239 6.74084 23.9582 6.74084C24.0955 6.74084 24.219 6.86653 24.219 7.00618C24.219 7.11791 24.1572 7.20868 24.0406 7.25058C23.9102 7.29248 23.7386 7.34834 23.5121 7.34834Z"
        fill="#F6F6F6"
      />
      <path
        d="M26.4287 7.3481C25.9552 7.3481 25.4061 7.15957 25.0287 6.87328C24.9806 6.83836 24.9257 6.74759 24.9257 6.65681C24.9257 6.51017 25.0493 6.3775 25.2002 6.3775C25.262 6.3775 25.3238 6.39845 25.3718 6.43336C25.715 6.68474 26.0719 6.81043 26.4493 6.81043C26.8748 6.81043 27.1699 6.58 27.1699 6.25879V6.24483C27.1699 5.94457 26.8817 5.80491 26.3395 5.65129L26.3258 5.64431L26.3121 5.63733C25.763 5.47672 25.0836 5.27422 25.0836 4.58293V4.56897C25.0836 3.9475 25.6052 3.49362 26.3258 3.49362C26.7033 3.49362 27.1288 3.61233 27.4925 3.82181L27.4994 3.82879C27.5886 3.89164 27.6366 3.97543 27.6366 4.07319C27.6366 4.21983 27.5131 4.3525 27.3621 4.3525C27.3072 4.3525 27.2523 4.33853 27.2111 4.3106C26.916 4.13604 26.6072 4.03828 26.3121 4.03828C25.914 4.03828 25.6395 4.24078 25.6395 4.53405V4.54802C25.6395 4.84129 26.0032 4.96698 26.518 5.1206C26.9709 5.24629 27.7259 5.46276 27.7259 6.20991V6.22388C27.719 6.86629 27.1768 7.3481 26.4287 7.3481Z"
        fill="#F6F6F6"
      />
      <path
        d="M28.9136 7.30619C28.7557 7.30619 28.6322 7.18749 28.6322 7.02688V2.25068C28.6322 2.09008 28.7488 1.96439 28.9067 1.96439C29.0645 1.96439 29.1812 2.09008 29.1812 2.25068V7.0199C29.1881 7.18749 29.0714 7.30619 28.9136 7.30619Z"
        fill="#F6F6F6"
      />
      <path
        d="M34.0948 7.35531C33.0516 7.35531 32.228 6.5104 32.228 5.42807V5.41411C32.228 4.3248 33.0516 3.4729 34.1085 3.4729C35.1517 3.4729 35.9752 4.31781 35.9752 5.40014V5.41411C35.9752 6.5104 35.1517 7.35531 34.0948 7.35531ZM34.0948 4.01756C33.3604 4.01756 32.8045 4.61109 32.8045 5.40712V5.42109C32.8045 6.20316 33.3741 6.82462 34.1085 6.82462C34.8428 6.82462 35.3987 6.23109 35.3987 5.43505V5.42109C35.3987 4.63204 34.8291 4.01756 34.0948 4.01756Z"
        fill="#F6F6F6"
      />
      <path
        d="M19.8473 7.35531C18.8247 7.35531 18.056 6.52436 18.056 5.42109V5.40712C18.056 4.3248 18.8178 3.4729 19.7924 3.4729C20.7669 3.4729 21.4738 4.2829 21.4738 5.39316C21.4738 5.53281 21.3434 5.66549 21.1993 5.66549H19.3326C19.1816 5.66549 19.0718 5.55376 19.0718 5.40712C19.0718 5.2535 19.1816 5.14178 19.3326 5.14178H20.8905C20.815 4.59712 20.4924 4.01057 19.7787 4.01057C19.1953 4.01057 18.7286 4.4854 18.6325 5.15574L18.6188 5.26747C18.6051 5.38618 18.6051 5.49092 18.6188 5.58868V5.59566C18.6874 6.31488 19.1953 6.81764 19.8473 6.81764C20.2453 6.81764 20.5748 6.67798 20.8836 6.37074C20.9385 6.31488 21.0003 6.29393 21.0689 6.29393C21.2199 6.29393 21.3434 6.41264 21.3434 6.56626C21.3434 6.61514 21.3297 6.69195 21.2542 6.76178C20.8493 7.18074 20.4307 7.35531 19.8473 7.35531Z"
        fill="#F6F6F6"
      />
      <path
        d="M36.9631 8.77251C36.8052 8.77251 36.6817 8.6538 36.6817 8.48622V5.24622C36.6817 4.17786 37.4915 3.47958 38.4523 3.47958C39.4955 3.47958 40.3191 4.3245 40.3191 5.40682V5.42079C40.3191 6.5101 39.4955 7.362 38.4386 7.362C37.9651 7.362 37.5396 7.18743 37.2307 6.86622V8.48622C37.2307 8.6538 37.1209 8.77251 36.9631 8.77251ZM37.2307 5.58139C37.2925 6.28665 37.7592 6.78243 38.3906 6.81734C38.4112 6.81734 38.4386 6.81734 38.4592 6.81734C38.6857 6.81734 38.8984 6.76148 39.0837 6.64975C39.1043 6.63579 39.1181 6.62881 39.1386 6.61484C39.2896 6.5101 39.42 6.37743 39.5161 6.20286C39.5298 6.18191 39.5367 6.16794 39.5504 6.147C39.571 6.11208 39.5916 6.07019 39.6053 6.02829C39.6808 5.83975 39.722 5.63725 39.722 5.42777V5.41381C39.722 5.24622 39.6946 5.07863 39.6465 4.92501C39.6328 4.88312 39.6191 4.84122 39.5985 4.79932C39.5847 4.75743 39.5642 4.71553 39.5436 4.68062C39.4269 4.47114 39.269 4.29657 39.07 4.17786C39.0494 4.16389 39.0357 4.15691 39.0151 4.14993C38.9808 4.12898 38.9396 4.11501 38.8984 4.10105C38.8161 4.07312 38.7337 4.04519 38.6514 4.03122C38.6102 4.02424 38.5622 4.01726 38.521 4.01726C38.5004 4.01726 38.4729 4.01726 38.4523 4.01726C37.7866 4.01726 37.3062 4.51303 37.2376 5.24622V5.58139H37.2307Z"
        fill="#F6F6F6"
      />
      <path
        d="M31.5967 7.30666C31.5212 7.30666 31.4526 7.27873 31.3977 7.22287L29.6957 5.47019C29.5927 5.36545 29.5858 5.20485 29.6819 5.09313L29.6888 5.07916L31.2398 3.61976C31.2879 3.5639 31.3497 3.53597 31.4183 3.52899C31.4938 3.52201 31.5624 3.54295 31.6173 3.59183C31.734 3.68959 31.7477 3.87114 31.6516 3.98985L31.6379 4.00382L30.2653 5.27468L31.782 6.83881C31.8369 6.89468 31.8644 6.9645 31.8644 7.04131C31.8644 7.11812 31.8369 7.18795 31.782 7.23683C31.7408 7.27873 31.6722 7.30666 31.5967 7.30666Z"
        fill="#F6F6F6"
      />
      <path
        d="M16.9852 7.35508C16.8274 7.35508 16.7039 7.2294 16.7039 7.06879V5.11362C16.7039 4.42931 16.3332 4.02431 15.7087 4.02431C15.0842 4.02431 14.7136 4.42931 14.7136 5.11362V7.06879C14.7136 7.2294 14.59 7.35508 14.4322 7.35508C14.2743 7.35508 14.1577 7.23638 14.1577 7.06879V5.05776C14.1577 4.1081 14.7479 3.49362 15.6538 3.49362H15.7499C16.6627 3.49362 17.246 4.1081 17.246 5.05776V7.06879C17.2529 7.23638 17.1431 7.35508 16.9852 7.35508Z"
        fill="#F6F6F6"
      />
      <path
        d="M42.8044 7.35531C41.7818 7.35531 41.0131 6.52436 41.0131 5.42109V5.40712C41.0131 4.3248 41.7749 3.4729 42.7494 3.4729C43.724 3.4729 44.4309 4.2829 44.4309 5.39316C44.4309 5.5398 44.3074 5.66549 44.1564 5.66549H42.2828C42.1318 5.66549 42.022 5.55376 42.022 5.40712C42.022 5.2535 42.1318 5.14178 42.2828 5.14178H43.8407C43.7652 4.59712 43.4426 4.01057 42.7289 4.01057C42.1455 4.01057 41.6788 4.4854 41.5827 5.15574L41.569 5.26747C41.5553 5.38618 41.5553 5.49092 41.569 5.58868V5.59566C41.6376 6.31488 42.1455 6.81764 42.7975 6.81764C43.1955 6.81764 43.525 6.67798 43.8338 6.37074C43.8887 6.31488 43.9505 6.29393 44.0191 6.29393C44.1701 6.29393 44.2936 6.41264 44.2936 6.56626C44.2936 6.61514 44.2799 6.69195 44.2044 6.76178C43.8064 7.18074 43.3877 7.35531 42.8044 7.35531Z"
        fill="#F6F6F6"
      />
    </svg>
  );
};

export default NetskapeIcon;
