import React, { useRef } from "react";
import { useTheme, useMediaQuery } from "@mui/material";
import {
  QuantifyLogo,
  ReduceLogo,
  AdaptLogo,
} from "../../../../../utils/icons";
import "./ecosystem-diagram.css";
import EcosystemLinearSVG from "./ecosystem-linear-svg";
import EcosystemEclipseSVG from "./ecosystem-eclipse-svg";

import EcosystemSingleLineSVG from "./ecosystem-single-line-svg";
import { FadeInSection, GreenItalicText } from "../../../../../components";

import { Translate } from "react-auto-translate";

import { ecosystemInputs } from "../../../../../utils/constants/website/ecosystem-diagram";
import EcosystemBgIcon from "./ecosystem-bg-icon";
import { LogoItem } from "../../../../../components";

const EcosystemDiagram = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isLinearView = useMediaQuery("(min-width: 1250px)");
  const domRef = useRef();
  const topBoxesRefs = useRef([]);

  return (
    <div className="max-w-screen md:mb-15 mb-0" ref={domRef}>
      <FadeInSection>
        <div className="flex justify-center xs:mt-5 mt-5 md:mt-7.5 px-1.875">
          <div className="xs:max-w-full md:max-w-78.75 w-full">
            <div>
              <div className="text-h1.5 text-center leading-[1.2] text-white">
                <GreenItalicText text="Unlimited " />
                <span>
                  <Translate> data in, </Translate>
                </span>
                <GreenItalicText text=" unlimited " />
                <span className="ml-0.313">
                  <Translate>applications out</Translate>
                </span>
              </div>
              <div
                role="heading"
                aria-level="4"
                className="text-center pt-0.625 text-white text-h4"
              >
                <Translate>
                  Quantify individual user risk, target training to users’
                  vulnerabilities, and adapt security controls for high-risk
                  users.
                </Translate>
              </div>
            </div>

            <div className="z-2 relative">
              <div className="mt-2.5">
                <div className="flex flex-row justify-around flex-wrap">
                  {ecosystemInputs.map((input, inputIndex) => (
                    <div
                      className="home-box-ecosystem-input bg-gray-dark rounded-small border border-gray border-opacity-50 w-17.25 md:pt-0.75 md:px-0.75 p-0.75 md:max-w-[70%] max-w-[90%] md:mt-2.5 overflow-hidden"
                      ref={(el) => (topBoxesRefs.current[inputIndex] = el)}
                      key={`ecosystem-input-${inputIndex}`}
                    >
                      <div className="flex justify-center">{input.icon}</div>
                      <div className="flex justify-center mt-0.75">
                        <span
                          role="heading"
                          aria-level="5"
                          className="text-white text-center text-h5 font-medium"
                          variant="body-emphasized-gray"
                        >
                          {<Translate>{input.title}</Translate>}
                        </span>
                      </div>
                      <div className="flex flex-col-reverse items-center justify-center h-1/2 my-auto w-full min-h-5 mt-0.5">
                        {/* Render first row */}
                        <div>
                          <div className="flex flex-row justify-center items-center">
                            {input.logoFirstRow.map((logo, logoIndex) => (
                              <div
                                className={`flex justify-evenly items-center text-xl ${
                                  logoIndex > -1 ? "mx-0.25" : ""
                                }`}
                                key={`ecosystem-input-${inputIndex}-logo-${logoIndex}`}
                              >
                                {logo}
                              </div>
                            ))}
                          </div>
                        </div>

                        {/* Render second row, if it exists */}
                        {input.logoSecondRow && (
                          <div className="flex justify-center my-0.5">
                            <div className="flex flex-row justify-center items-center">
                              {input.logoSecondRow.map((logo, logoIndex) => (
                                <div
                                  className={`flex justify-center items-center text-xl ${
                                    logoIndex > -1 ? "mx-0.25" : ""
                                  }`}
                                  key={`ecosystem-input-${inputIndex}-logo-${logoIndex}`}
                                >
                                  {logo}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}

                        {/* Render third row, if it exists */}
                        {input.logoThirdRow && (
                          <div className="flex justify-center items-center">
                            <div className="flex flex-row justify-center items-center">
                              {input.logoThirdRow.map((logo, logoIndex) => (
                                <div
                                  className={`flex justify-evenly items-center text-xl ${
                                    logoIndex > -1 ? "mx-0.25" : ""
                                  }`}
                                  key={`ecosystem-input-${inputIndex}-logo-${logoIndex}`}
                                >
                                  {logo}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div
              className={`flex justify-center items-center ${
                isLinearView ? "-mt-1" : "-mt-37.5"
              } -z-10 relative`}
            >
              {isLinearView ? (
                <EcosystemLinearSVG isMobile={isMobile} />
              ) : (
                <EcosystemSingleLineSVG className="absolute inset-0 z-[-1]" />
              )}
            </div>

            {/* Logo positioned relative to the background */}
            <div className="flex justify-center h-1.75 mt-1.5 z-20 relative">
              <div className="md:mt-3 -mt-0.5">
                <LogoItem />
              </div>
            </div>

            <div className="hidden md:block z-10 relative xs:flex xs:flex-col xs:items-center xs:mt-2.5">
              <div className="flex justify-center mt-0.75">
                <div
                  className={`flex ${
                    isMobile ? "flex-col" : "flex-row"
                  } justify-between ${
                    isMobile ? "max-w-25" : "max-w-43.75"
                  } w-full`}
                >
                  <div
                    className={`bg-[#191919] bg-opacity-90 rounded-small border border-gray border-opacity-50 p-0.5 ${
                      isMobile ? "mb-0.5" : "0"
                    }`}
                    style={{
                      transform: "translateX(-140px) translateY(0px)",
                    }}
                  >
                    <div className="flex flex-row w-fit items-center mx-auto">
                      <div className="flex items-center">
                        <AdaptLogo />
                      </div>
                      <div className="flex items-center ml-0.5">
                        <span
                          role="heading"
                          aria-level="5"
                          className="text-left max-w-8 text-white text-h5 font-medium"
                        >
                          <Translate>Adapt security controls</Translate>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`bg-[#191919] bg-opacity-90 rounded-small border border-gray border-opacity-50 p-0.5 ${
                      isMobile ? "mb-0.5" : "0"
                    }`}
                    style={{ transform: "translateX(140px) translateY(0px)" }}
                  >
                    <div className="flex flex-row w-fit items-center mx-auto">
                      <div className="flex items-center">
                        <QuantifyLogo />
                      </div>
                      <div
                        role="heading"
                        aria-level="5"
                        className="flex items-center ml-0.5"
                      >
                        <span
                          role="heading"
                          aria-level="5"
                          className="text-left max-w-8 text-white text-h5 font-medium"
                        >
                          <Translate>Quantify Employee Risk</Translate>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center mt-0.5">
                <div
                  className={`bg-[#191919] bg-opacity-90 rounded-small border border-gray border-opacity-50 p-0.5 -mt-0.5  ${
                    isMobile ? "w-43.75" : "max-w-43.75"
                  } xs:w-full md:w-auto`}
                  style={{ transform: "translateY(60px)" }}
                >
                  <div className="flex justify-center items-center w-full mx-auto">
                    <div
                      className="flex items-center"
                      display="flex"
                      alignItems="center"
                    >
                      <ReduceLogo />
                    </div>
                    <div className="flex items-center ml-0.5">
                      <span
                        role="heading"
                        aria-level="5"
                        className="text-left text-h5 text-white font-medium max-w-8"
                      >
                        <Translate>Automatically reduce risk</Translate>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Logo for mobile */}
            <div
              className="absolute -bottom-3 left-1/2 transform -translate-x-1/2 mb-14 text-white md:hidden"
              style={{ zIndex: 50, width: "250px" }}
            >
              <div className="py-0.25">
                <div className="flex justify-center items-center border border-gray border-opacity-50 bg-[#191919] bg-opacity-90 rounded-small p-0.25 py-0.5">
                  <div className="mr-0.25">
                    <AdaptLogo />
                  </div>
                  <div>
                    <Translate>Adapt security controls</Translate>
                  </div>
                </div>
              </div>
              <div className="py-0.25">
                <div className="flex justify-center  items-center border border-gray border-opacity-50 bg-[#191919] bg-opacity-90 rounded-small p-0.25 py-0.5">
                  <div className="mr-0.25">
                    <QuantifyLogo />
                  </div>
                  <div>
                    <Translate>Quantify Employee Risk</Translate>
                  </div>
                </div>
              </div>
              <div className="py-0.25">
                <div className="flex justify-center  items-center border border-gray border-opacity-50 bg-[#191919] bg-opacity-90 rounded-small p-0.25 py-0.5">
                  <div className="mr-0.25">
                    <ReduceLogo />
                  </div>
                  <div>
                    <Translate>Automatically reduce risk</Translate>
                  </div>
                </div>
              </div>
            </div>

            {/* Ecosystem diagram for mobile */}
            <div className="md:hidden block text-white -mt-7.5">
              <div className="flex justify-center -mt-10">
                <div className="w-20 h-20">
                  <EcosystemBgIcon />
                </div>
              </div>
            </div>

            {!isMobile && (
              <div className="relative w-full mx-auto flex justify-center -translate-y-12.5">
                <EcosystemEclipseSVG />
              </div>
            )}
            {isMobile && (
              <div className="relative w-full mx-auto flex justify-center -translate-y-18">
                <EcosystemEclipseSVG />
              </div>
            )}

            {/* Ecosystem diagram for desktop */}
            <div className="hidden md:block">
              <div
                className={`flex justify-center items-center relative z-1 ${
                  isMobile ? "-mt-12.5" : "-mt-10.5"
                } ${isMobile ? "mb-5" : "mb-0"}`}
              >
                <div className="md:relative flex justify-center">
                  <div
                    className="md:absolute md:inset-0 z-0"
                    style={{ top: "-380px", left: "-260px" }}
                  >
                    <EcosystemBgIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FadeInSection>
    </div>
  );
};

export default EcosystemDiagram;
