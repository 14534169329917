export const constants = {
  // Pages
  BASELINE_RISK_ASSESSMENT: "Baseline Risk Assessment",
  CAMPAIGNS: "Campaigns",
  COMPLIANCE: "Compliance",
  COMPANY: "Company",
  DEPARTMENT: "Department",
  EMPLOYEES: "Employees",
  END_USER: "End user",
  INSIGHTS: "Insights",
  MY_PROFILE: "My profile",
  ORGANIZATION: "Organization",
  REPORTING: "Reporting",
  SEARCH_USER: "Search user",
  TEAM: "Team",
  USER: "User",
  USERS: "Users",
  WELCOME_TO_DUNE_SECURITY: "Welcome to Dune Security",
  WELCOME: "Welcome",

  // Error messages
  ERROR_DEFAULT: "An error has occurred. Please try again later.",
  ERROR_EMPTY_FIELDS: "Please fill the required values.",

  // AWS
  USER_POOL_ID: "",

  // Styles,
  MARGIN_SMALL: "8px",
  MARGIN_MEDIUM: "20px",
  MARGIN_REGULAR: "28px",
  MARGIN_LARGE: "40px",
  MARGIN_XL: "80px",
  MARGIN_2XL: "160px",
  MARGIN_WEBSITE_SECTION: {
    xs: "80px",
    md: "160px",
  },

  // Other
  BRAND_MARK_WHITE: "Brank Mark White",
};

export { default as pagesPlatform } from "./pages-platform.js";
export { default as pagesPlatformCraftVentures } from "./pages-platform-craft-ventures.js";
export { default as pagesPlatformDuringBRA } from "./pages-platform-during-bra.js";
export { default as pagesPlatformEndUser } from "./pages-platform-end-user.js";
export { default as pagesPlatformEndUserBeforeBRA } from "./pages-platform-end-user-before-bra.js";
export { default as pagesPlatformManager } from "./pages-platform-manager.js";
export { default as pagesPlatformOnboarding } from "./pages-platform-onboarding.js";
export { default as pagesPlatformWithNudge } from "./pages-platform-with-nudge.js";
export { default as pagesWebsiteLeft } from "./pages-website-left.js";
export { default as pagesWebsiteRight } from "./pages-website-right.js";
