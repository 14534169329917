import {
  IntegrationInstructionsFilledIcon,
  IntegrationInstructionsOutlinedIcon,
} from "../utils/icons";

const pagesPlatformCraftVentures = {
  INTEGRATIONS: {
    name: "Integrations",
    path: "/integrations/",
    icon: <IntegrationInstructionsOutlinedIcon />,
    activeIcon: <IntegrationInstructionsFilledIcon />,
    tooltip: "Go to the integrations page.",
  },
};

export default pagesPlatformCraftVentures;
