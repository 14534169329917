import React, { cloneElement, useCallback, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useMediaQuery, useTheme } from "@mui/material";

import { useDescope, useSession, useUser } from "@descope/react-sdk";

import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";

import {
  getCurrentMSSPOrganization,
  getMSSPOrganizations,
  getUser,
} from "../../../api";

import { Breadcrumbs } from "../../common";

import { NavigationPlatformSide, NavigationPlatformTop } from "../../layout";

import PlatformMobile from "../platform-mobile/platform-mobile";

import "./platform-container.css";

function PlatformContainer({
  title,
  mainPage,
  badgeTitle,
  badgeIcon,
  tracker,
  onFinishInitialLoading,
}) {
  const theme = useTheme();
  let isScreenDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Loading
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingLogOut, setIsLoadingLogOut] = useState(false);
  const [isPlatformLoading, setIsPlatformLoading] = useState(false);

  // Messages (error and success)
  const [messageError, setMessageError] = useState("");

  // Cognito user access
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperadmin, setIsSuperadmin] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  // User
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [initials, setInitials] = useState("");
  const [signedInEmail, setSignedInEmail] = useState("");
  const [organization, setOrganization] = useState("");
  const [isInCampaign, setIsInCampaign] = useState(false);
  const [hasBRAStarted, setHasBRAStarted] = useState(false);
  const [isBRACompleted, setIsBRACompleted] = useState(false);
  const [startTimestamp, setStartTimestamp] = useState(null);
  const [msspOrganizations, setMSSPOrganizations] = useState([]);
  const [selectedMSSPOrganization, setSelectedMSSPOrganization] = useState({});
  const [isMobileView, setIsMobileView] = useState(isMobileScreen);

  const [email, setEmail] = useState("");

  // Other
  const [newTitle, setNewTitle] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const location = useLocation();

  const { isAuthenticated, isSessionLoading } = useSession();
  const { user, isUserLoading } = useUser();
  const { logout } = useDescope();

  useEffect(() => {
    setIsMobileView(isMobileScreen);
  }, [isMobileScreen]);

  const handleClickLogOut = async () => {
    logOut();
  };

  const handleClickMSSPOrganization = async (msspOrganization) => {
    setSelectedMSSPOrganization(msspOrganization);

    if (email !== "david@dune.demo") {
      const currentMSSPOrganizationData = await getCurrentMSSPOrganization(
        msspOrganization.id,
      );

      if (Object.keys(currentMSSPOrganizationData.error).length > 0) {
        console.error(currentMSSPOrganizationData.error.message);
      } else {
        setIsLoading(true);

        if (onFinishInitialLoading) {
          onFinishInitialLoading({
            isInitialLoading: true,
            email,
            isInCampaign,
          });
        }

        loadUserInfo().finally(() => {
          setIsLoading(false);

          if (onFinishInitialLoading) {
            onFinishInitialLoading({
              isInitialLoading: false,
              email,
              isInCampaign,
            });
          }
        });
      }
    }
  };

  const logOut = useCallback(async () => {
    setIsLoadingLogOut(true);
    await logout();
    setIsLoadingLogOut(false);
  }, [logout]);

  const loadDescopeUser = useCallback(async () => {
    const { email, roleNames, userTenants } = user;

    setSignedInEmail(email);

    let tenantRoles = [];

    if (userTenants && userTenants.length > 0 && userTenants[0].roleNames) {
      tenantRoles = userTenants[0].roleNames;
    }

    if (
      roleNames.includes("Superadmin") ||
      tenantRoles.includes("Superadmin")
    ) {
      setIsSuperadmin(true);
    }

    if (roleNames.includes("Admin") || tenantRoles.includes("Admin")) {
      setIsAdmin(true);
    }

    if (roleNames.includes("Manager") || tenantRoles.includes("Manager")) {
      setIsManager(true);
    }

    // Set the user ID for OpenReplay.
    if (tracker) {
      tracker.setUserID(email);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracker, isUserLoading, user]);

  const loadUserInfo = useCallback(async () => {
    const userInfo = await getUser();

    if (Object.keys(userInfo.error).length > 0) {
      setMessageError(userInfo.error.message);

      // Log out if the user is not in the database.
      await logOut();
    } else {
      const {
        firstName,
        lastName,
        email,
        initials,
        organization,
        isInCampaign,
        hasBRAStarted,
        isBRACompleted,
        startTimestamp,
      } = userInfo.result;

      setFirstName(firstName);
      setLastName(lastName);
      setInitials(initials);
      setOrganization(organization);
      setIsInCampaign(isInCampaign);
      setHasBRAStarted(hasBRAStarted);
      setIsBRACompleted(isBRACompleted);
      setStartTimestamp(startTimestamp);

      setEmail(email);

      console.log(isPlatformLoading); // To delete.
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logOut, isUserLoading, user]);

  useEffect(() => {
    const updateComponent = async () => {
      // Remove isFederatedSignIn from localStorage.
      const isFederatedSignIn = localStorage.getItem("isFederatedSignIn");

      if (isFederatedSignIn) {
        localStorage.removeItem("isFederatedSignIn");
      }
    };

    updateComponent();
  }, [mainPage]);

  useEffect(() => {
    const loadMSSPOrganizations = async () => {
      if (email === "david@dune.demo") {
        // Demo account
        setMSSPOrganizations([
          {
            id: "0",
            name: "Dune Security",
            email: "david@dune.demo",
          },
          {
            id: "1",
            name: "Apple",
            email: "david@dune.demo",
          },
          {
            id: "2",
            name: "Amazon",
            email: "david@dune.demo",
          },
          {
            id: "3",
            name: "Google",
            email: "david@dune.demo",
          },
          {
            id: "4",
            name: "Microsoft",
            email: "david@dune.demo",
          },
          {
            id: "5",
            name: "Nvidia",
            email: "david@dune.demo",
          },
        ]);
      } else {
        const msspOrganizationsData = await getMSSPOrganizations();

        if (Object.keys(msspOrganizationsData.error).length > 0) {
          console.error(msspOrganizationsData.error.message);
        } else {
          const { msspOrganizations } = msspOrganizationsData.result;

          setMSSPOrganizations(msspOrganizations);
        }
      }
    };

    const loadFingerprintJS = async () => {
      const fpPromise = FingerprintJS.load({
        apiKey: process.env.REACT_APP_FINGERPRINTJS_KEY,
        endpoint: ["https://fp.dunesecurity.io", FingerprintJS.defaultEndpoint],
        scriptUrlPattern: [
          "https://fp.dunesecurity.io/web/v<version>/<apiKey>/loader_v<loaderVersion>.js",
          FingerprintJS.defaultScriptUrlPattern,
        ],
      });

      fpPromise
        .then((fp) =>
          fp.get({
            linkedId: email,
          }),
        )
        .catch((error) => {
          console.error("Error tagging user with FingerprintJS:", error);
        });
    };

    if (!isLoadingLogOut) {
      if (!isSessionLoading && !isAuthenticated) {
        setIsLoggedIn(false);
      } else if (!isSessionLoading && !isUserLoading) {
        setIsLoading(true);

        loadDescopeUser().finally(() => {
          loadMSSPOrganizations().finally(() => {
            if (email === "david@dune.demo") {
              setSelectedMSSPOrganization({
                id: "0",
                name: "Dune Security",
                email: "david@dune.demo",
              });
            } else {
              const filteredMSSPOrganizationsByEmail = msspOrganizations.filter(
                (organization) => organization.email === email,
              );

              if (filteredMSSPOrganizationsByEmail.length > 0) {
                setSelectedMSSPOrganization(
                  filteredMSSPOrganizationsByEmail[0],
                );
              } else if (msspOrganizations.length > 0) {
                setSelectedMSSPOrganization(msspOrganizations[0]);
              }
            }

            loadUserInfo().finally(() => {
              setIsLoading(false);

              loadFingerprintJS();

              if (onFinishInitialLoading) {
                onFinishInitialLoading({
                  isInitialLoading: false,
                  email,
                  isInCampaign,
                });
              }
            });
          });
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAuthenticated,
    isSessionLoading,
    isUserLoading,
    loadDescopeUser,
    loadUserInfo,
    onFinishInitialLoading,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setNewTitle(null);
    setBreadcrumbs([]);
  }, [location.pathname]);

  if (!isLoggedIn) {
    if (messageError) {
      return <Navigate to="/error/" />;
    }

    return <Navigate to="/login/" />;
  }

  const routesWelcome = [
    "/welcome/",
    "/sso-settings/",
    "/compliance-onboarding/",
    "/employees/",
    "/campaigns/",
    "/integrations/",
  ];

  if (
    !isLoading &&
    (isSuperadmin || isAdmin) &&
    !isBRACompleted &&
    !routesWelcome.includes(window.location.pathname)
  ) {
    return <Navigate to="/welcome/" />;
  }

  // List of pages only admins/superadmins are able to see
  const routesAdmin = [
    "/insights/organization/",
    "/insights/departments/search/",
    "/insights/departments/",
    "/insights/users/search/",
    "/insights/users/",
    "/dashboard/insights/",
    "/dashboard/trainings/",
    "/dashboard/trainings/learning/",
    "/dashboard/trainings/compliance/",
    "/dashboard/trainings/completed/",
    "/compliance/",
    "/reporting/",
    "/configurations/",
    "/configurations/test-frequency/",
    "/welcome/",
    "/sso-settings/",
    "/compliance-onboarding/",
    "/employees/",
    "/campaigns/",
    "/settings/dune-nudge/",
    "/integrations/",
    "/onboarding/",
    "/configure/testing/",
    "/configure/testing/schedule/",
    "/configure/trainings/",
  ];

  // List of pages only managers are able to see
  const routesManager = [
    "/insights/your-reports/",
    "/insights/users/",
    "/dashboard/insights/",
    "/dashboard/trainings/",
    "/dashboard/trainings/learning/",
    "/dashboard/trainings/compliance/",
    "/dashboard/trainings/completed/",
  ];

  const routesEndUser = [
    "/dashboard/insights/",
    "/dashboard/trainings/",
    "/dashboard/trainings/learning/",
    "/dashboard/trainings/compliance/",
    "/dashboard/trainings/completed/",
  ];

  if (
    !isLoading &&
    ((!isSuperadmin &&
      !isAdmin &&
      !isManager &&
      !routesEndUser.includes(window.location.pathname)) ||
      ((isSuperadmin || isAdmin) &&
        !routesAdmin.includes(window.location.pathname)) ||
      (isManager && !routesManager.includes(window.location.pathname)))
  ) {
    return <Navigate to="/error/" />;
  }

  const routesNotAllowedAfterBRA = [
    // Pages should not be accessible after the BRA is completed.
    "/welcome/",
    "/compliance-onboarding/",
    "/employees/",
    "/campaigns/",
  ];

  if (
    isBRACompleted &&
    routesNotAllowedAfterBRA.includes(window.location.pathname)
  ) {
    return (
      <Navigate
        to={
          isSuperadmin || isAdmin
            ? "/insights/organization/"
            : isManager
              ? "/insights/your-reports/"
              : "/dashboard/insights/"
        }
      />
    );
  }

  // Special case: Onboarding screen does not use the regular template.
  if (window.location.pathname === "/onboarding/") {
    return (
      <div className="w-screen h-screen">
        {cloneElement(mainPage, {
          isInitialLoading: isLoading,
          email,
        })}
      </div>
    );
  }

  return (
    <>
      <div style={{ display: isMobileView ? "block" : "none" }}>
        <PlatformMobile />
      </div>
      <div style={{ display: isMobileView ? "none" : "block" }}>
        <div className="w-full max-w-full">
          <div className="flex min-h-screen">
            {isScreenDesktop && (
              <div className="max-w-[200px] w-full platform-container-box-navigation-side">
                <NavigationPlatformSide
                  email={email}
                  organization={organization}
                  isInitialLoading={isLoading}
                  isBRACompleted={isBRACompleted}
                  isAdmin={isSuperadmin || isAdmin}
                  isManager={isManager}
                  msspOrganizations={msspOrganizations}
                  selectedMSSPOrganization={selectedMSSPOrganization}
                  onClickMSSPOrganization={handleClickMSSPOrganization}
                />
              </div>
            )}
            <div
              className="flex flex-col items-center w-full min-w-0"
              role="main"
            >
              <div className="w-full platform-container-box-navigation-top">
                <div className="flex flex-col-reverse justify-between w-full border-b border-border-color md:items-center md:flex-row min-h-[63px]">
                  <div className="p-0.75">
                    <div className="w-full mx-auto max-w-platform">
                      <div className="flex items-center">
                        <div role="heading" aria-level="2" className="h2">
                          {organization}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <NavigationPlatformTop
                      email={signedInEmail}
                      firstName={firstName}
                      initials={initials}
                      lastName={lastName}
                      onLogOut={handleClickLogOut}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full p-2">
                <div className="pb-8.25 mx-auto max-w-platform">
                  {breadcrumbs && breadcrumbs.length > 0 && (
                    <div className="mb-0.5">
                      <Breadcrumbs pages={breadcrumbs} />
                    </div>
                  )}
                  {(title || newTitle) && (
                    <div role="heading" aria-level="2" className="mb-2 h2">
                      {newTitle ? newTitle : title}
                    </div>
                  )}
                  <div>
                    {cloneElement(mainPage, {
                      isInitialLoading: isLoading,
                      email,
                      setIsPlatformLoading: setIsPlatformLoading,
                      setNewTitle: setNewTitle,
                      setBreadcrumbs: setBreadcrumbs,

                      firstName: firstName,
                      lastName: lastName,
                      organization: organization,
                      hasBRAStarted: hasBRAStarted,
                      isBRACompleted: isBRACompleted,
                      isInCampaign: isInCampaign,
                      startTimestamp: startTimestamp,
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PlatformContainer;
