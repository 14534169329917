import React from "react";

const PayrollIcon = () => {
  return (
    <svg
      width="38"
      height="21"
      viewBox="0 0 38 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.20819"
        y="1.02204"
        width="35.7067"
        height="18.7067"
        rx="2.85337"
        stroke="white"
        strokeWidth="0.815249"
      />
      <path
        d="M7.19433 11.4803H5.86267V13.8754H4.80883V6.99674H7.19433C8.73677 6.99674 9.73312 7.78233 9.73312 9.21938C9.73312 10.666 8.73677 11.4803 7.19433 11.4803ZM7.18475 7.88772H5.86267V10.5894H7.18475C8.11405 10.5894 8.67929 10.0816 8.67929 9.22896C8.67929 8.37631 8.11405 7.88772 7.18475 7.88772ZM13.6923 13.8754V13.0898C13.3761 13.6551 12.8109 13.9712 12.0636 13.9712C11.0098 13.9712 10.32 13.3677 10.32 12.4384C10.32 11.442 11.1152 10.9247 12.6289 10.9247C12.9258 10.9247 13.1654 10.9343 13.5773 10.9822V10.6085C13.5773 9.88043 13.1845 9.46847 12.5139 9.46847C11.8049 9.46847 11.3738 9.89001 11.3451 10.599H10.4637C10.5116 9.45889 11.3259 8.70204 12.5139 8.70204C13.7689 8.70204 14.497 9.41099 14.497 10.6181V13.8754H13.6923ZM11.2397 12.4096C11.2397 12.9366 11.6325 13.2814 12.2552 13.2814C13.0696 13.2814 13.5773 12.7737 13.5773 11.9977V11.5857C13.2037 11.5378 12.9354 11.5282 12.6863 11.5282C11.7187 11.5282 11.2397 11.8157 11.2397 12.4096ZM19.847 8.79785L17.3083 15.7244H16.2544L16.9825 13.8084L14.9802 8.79785H16.0245L17.4999 12.6683L18.8698 8.79785H19.847ZM21.5017 11.2792V13.8754H20.5341V8.79785H21.4347V9.87085C21.7892 9.17148 22.5556 8.73078 23.3891 8.73078V9.73672C22.2969 9.67924 21.5017 10.1583 21.5017 11.2792ZM26.263 13.9712C24.8259 13.9712 23.7721 12.8408 23.7721 11.3366C23.7721 9.83252 24.8259 8.70204 26.263 8.70204C27.7 8.70204 28.7538 9.83252 28.7538 11.3366C28.7538 12.8408 27.7 13.9712 26.263 13.9712ZM26.263 13.1186C27.0964 13.1186 27.7575 12.448 27.7575 11.3366C27.7575 10.2253 27.0964 9.56427 26.263 9.56427C25.4295 9.56427 24.778 10.2253 24.778 11.3366C24.778 12.448 25.4295 13.1186 26.263 13.1186ZM30.7174 13.8754H29.7402V6.99674H30.7174V13.8754ZM33.0377 13.8754H32.0605V6.99674H33.0377V13.8754Z"
        fill="white"
      />
    </svg>
  );
};

export default PayrollIcon;
