import React from "react";

const BasicUserDataLogo = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_232_22864)">
        <path
          d="M16.9846 21.4474C17.9118 21.7166 18.8725 21.8527 19.838 21.8517C21.3906 21.854 22.9231 21.5 24.3175 20.8169C24.3587 19.8426 24.0812 18.8815 23.527 18.0791C22.9728 17.2768 22.1722 16.6769 21.2464 16.3706C20.3206 16.0642 19.3202 16.068 18.3968 16.3813C17.4734 16.6947 16.6772 17.3005 16.1291 18.107M16.9846 21.4474V21.4441C16.9846 20.2343 16.6737 19.0962 16.1291 18.107M16.9846 21.4474V21.5626C14.8926 22.8225 12.496 23.4864 10.0539 23.4822C7.52009 23.4822 5.14935 22.7811 3.12536 21.5626L3.12427 21.4441C3.12343 19.9054 3.63474 18.4102 4.57757 17.1942C5.5204 15.9783 6.84112 15.1106 8.33153 14.7282C9.82193 14.3458 11.3972 14.4702 12.8091 15.082C14.2209 15.6938 15.389 16.7581 16.1291 18.107M13.7236 7.58488C13.7236 8.55786 13.3371 9.49099 12.6491 10.179C11.9611 10.867 11.028 11.2535 10.055 11.2535C9.082 11.2535 8.14887 10.867 7.46087 10.179C6.77287 9.49099 6.38635 8.55786 6.38635 7.58488C6.38635 6.6119 6.77287 5.67877 7.46087 4.99077C8.14887 4.30277 9.082 3.91626 10.055 3.91626C11.028 3.91626 11.9611 4.30277 12.6491 4.99077C13.3371 5.67877 13.7236 6.6119 13.7236 7.58488ZM22.6913 10.0306C22.6913 10.7874 22.3907 11.5132 21.8556 12.0483C21.3205 12.5834 20.5947 12.884 19.838 12.884C19.0812 12.884 18.3554 12.5834 17.8203 12.0483C17.2852 11.5132 16.9846 10.7874 16.9846 10.0306C16.9846 9.27387 17.2852 8.5481 17.8203 8.01299C18.3554 7.47788 19.0812 7.17726 19.838 7.17726C20.5947 7.17726 21.3205 7.47788 21.8556 8.01299C22.3907 8.5481 22.6913 9.27387 22.6913 10.0306Z"
          stroke="#00FF82"
          strokeWidth="1.39757"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_232_22864">
          <rect
            width="26.088"
            height="26.088"
            fill="white"
            transform="translate(0.679626 0.655273)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BasicUserDataLogo;
