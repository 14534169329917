import React from "react";

const Tabs = ({ data = [], selectedTab = { name: "" }, setSelectedTab }) => {
  const handleClickTab = (tab) => {
    if (setSelectedTab) {
      setSelectedTab(tab);
    }
  };

  return (
    <div className="flex border-b-2 border-border-color">
      {data.map((tab, index) => (
        <button
          className={`body uppercase pb-0.625 ${
            selectedTab &&
            selectedTab.name &&
            tab &&
            tab.name &&
            selectedTab.name === tab.name
              ? "border-b-2 border-green -mb-[2px] backdrop-blur-sm"
              : "text-gray-placeholder"
          } ${index > 0 ? "ml-2" : ""}`}
          onClick={() => handleClickTab(tab)}
          key={`tab-${index}`}
        >
          {tab.name}
        </button>
      ))}
    </div>
  );
};

export default Tabs;
