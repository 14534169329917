import { getResponseGet } from "../utils";

/**
 * Fetches a list of departments.
 * @returns {Object} An object containing the list of departments or an error.
 *   - The 'result' field is an array of departments.
 *   - The 'error' field contains an error object if the request fails.
 */
const getDepartments = async () => {
  let departmentsData = {
    result: {
      departments: [],
    },
    error: {},
  };

  try {
    const response = await getResponseGet("/list_departments/");

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data.departments && Array.isArray(data.departments)) {
      const departments = [];

      for (let i = 0; i < data.departments.length; i++) {
        const departmentData = data.departments[i];

        if (departmentData.name) {
          departments.push({
            departmentName: departmentData.name,
            riskScore: departmentData.risk_score
              ? +departmentData.risk_score.toFixed()
              : 0,
          });
        }
      }

      departments.sort((a, b) => {
        if (!a.name) {
          return 1;
        }

        if (!b.name) {
          return -1;
        }

        return a.name.localeCompare(b.name);
      });

      departmentsData.result.departments = departments;
    }
  } catch (error) {
    departmentsData.error = error;
  }

  return departmentsData;
};

export default getDepartments;
