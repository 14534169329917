import React from "react";
import { BackIcon, NextIcon, LastIcon, FirstIcon } from "../../../utils";

const ConfigureNavigation = ({
  page,
  totalPages,
  loading,
  onMoveFirstPage,
  onMovePreviousPage,
  onMoveNextPage,
  onMoveLastPage,
}) => {
  return (
    <div className="flex items-center justify-end">
      <div className="flex items-center space-x-1">
        <div className="flex justify-start items-center my-1 space-x-0.5">
          <div className="cursor-pointer" onMouseDown={onMoveFirstPage}>
            <FirstIcon />
          </div>
          <div className="cursor-pointer" onMouseDown={onMovePreviousPage}>
            <BackIcon />
          </div>
          <div className="my-0.5 mr-0.25">
            {loading ? (
              <div>Loading...</div>
            ) : (
              <div>
                Page <span className="font-semibold">{page}</span> of{" "}
                <span className="font-semibold">{totalPages}</span>
              </div>
            )}
          </div>
          <div className="cursor-pointer" onMouseDown={onMoveNextPage}>
            <NextIcon />
          </div>
          <div className="cursor-pointer" onMouseDown={onMoveLastPage}>
            <LastIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigureNavigation;
