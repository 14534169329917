import React, { useEffect, useState } from "react";

import {
  Box,
  Card,
  CardContent,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { getDepartments, getTestFrequencies } from "../../../api";

import "./test-frequency.css";

function TestFrequency({ isInitialLoading, email }) {
  // Test frequencies
  const [testFrequencies, setTestFrequencies] = useState([]);

  useEffect(() => {
    const loadDepartments = async () => {
      let departments = [];

      const departmentsData = await getDepartments();

      if (Object.keys(departmentsData.error).length > 0) {
        console.error(departmentsData.error.message);
      } else {
        departments = departmentsData.result;
      }

      return departments;
    };

    const loadTestFrequencies = async () => {
      let testFrequencies = {};

      const testFrequenciesData = await getTestFrequencies();

      if (Object.keys(testFrequenciesData.error).length > 0) {
        console.error(testFrequenciesData.error.message);
      } else {
        testFrequencies = testFrequenciesData.result;
      }

      return testFrequencies;
    };

    const updateComponent = async () => {
      const departments = await loadDepartments();

      if (departments.length > 0) {
        const testFrequencies = [];

        const testFrequenciesData = await loadTestFrequencies();

        for (let i = 0; i < departments.length; i++) {
          const departmentName = departments[i].name || "";

          let frequency = [null, null, null];
          if (testFrequenciesData[departmentName]) {
            // Check if there are existing test frequencies.
            frequency = testFrequenciesData[departmentName];
          }

          const testFrequency = {
            name: departmentName,
            frequency,
          };

          testFrequencies.push(testFrequency);
        }

        setTestFrequencies(testFrequencies);
      }
    };

    if (!isInitialLoading && email) {
      updateComponent();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialLoading, email]);

  return (
    <Box marginTop="60px">
      <Box>
        <Typography
          role="heading"
          aria-level="2"
          component="h2"
          className="dune-text-header-dashboard"
        >
          Test frequency
        </Typography>
        <Typography component="p" marginTop="40px">
          Configure the number of phishing tests sent to each department per
          quarter. Adjust the minimum and maximum frequency to fit your security
          needs.
        </Typography>
      </Box>
      <Box marginTop="60px">
        <Grid container>
          {testFrequencies.map((testFrequencyData, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              marginTop={{
                xs: index !== 0 ? "40px" : 0,
                sm: index !== 0 && index !== 1 ? "40px" : 0,
                lg: index > 2 ? "40px" : 0,
              }}
              paddingLeft={{
                xs: 0,
                sm: index % 2 === 1 ? "20px" : 0,
                lg:
                  index % 3 === 2
                    ? `${(40 / 3) * 2}px`
                    : index % 3 === 1
                      ? `${40 / 3}px`
                      : 0,
              }}
              paddingRight={{
                xs: 0,
                sm: index % 2 === 0 ? "20px" : 0,
                lg:
                  index % 3 === 0
                    ? `${(40 / 3) * 2}px`
                    : index % 3 === 1
                      ? `${40 / 3}px`
                      : 0,
              }}
              key={`test-frequency-data-${index}`}
            >
              <Card className="dune-card-dashboard test-frequency-card">
                <CardContent>
                  <Typography
                    role="heading"
                    aria-level="3"
                    component="h3"
                    className="test-frequency-text-header-department"
                  >
                    {testFrequencyData.name}
                  </Typography>
                  <Box marginTop="20px">
                    <Stack direction="row">
                      <Box>
                        <InputLabel className="test-frequency-input-label">
                          Min.
                        </InputLabel>
                        <TextField
                          variant="filled"
                          className="dune-text-field-filled test-frequency-text-field"
                          name={`department-${index}-min`}
                          type="number"
                          value={testFrequencyData.frequency[0]}
                          required
                          inputProps={{
                            style: { textAlign: "center" },
                          }}
                          InputProps={{
                            disableUnderline: true,
                          }}
                        />
                      </Box>
                      <Typography
                        component="p"
                        className="test-frequency-text-to"
                        marginLeft="20px"
                        marginRight="20px"
                        marginTop="44px"
                      >
                        to
                      </Typography>
                      <Box>
                        <InputLabel className="test-frequency-input-label">
                          Max.
                        </InputLabel>
                        <TextField
                          variant="filled"
                          className="dune-text-field-filled test-frequency-text-field"
                          name={`department-${index}-max`}
                          type="number"
                          value={testFrequencyData.frequency[1]}
                          required
                          inputProps={{
                            style: { textAlign: "center" },
                          }}
                          InputProps={{
                            disableUnderline: true,
                          }}
                        />
                      </Box>
                    </Stack>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default TestFrequency;
