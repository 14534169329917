import React from "react";
import { useNavigate } from "react-router-dom";

import {
  ButtonOutlinedGreen,
  CardWithLoadingState,
  UsersTable,
} from "../../..";

const headers = [
  {
    id: "departmentName",
    label: "Department",
    isSortable: true,
    isSearchable: false,
    align: "left",
    width: "60%",
  },
  {
    id: "riskScore",
    label: "Risk score",
    isSortable: true,
    isSearchable: false,
    align: "left",
    width: "40%",
  },
];

function CardDepartmentRiskScores({ departments }) {
  const navigate = useNavigate();

  const handleClickViewMore = () => {
    navigate("/insights/departments/");
  };

  return (
    <CardWithLoadingState loadingVariable={departments} minHeight="317px">
      <UsersTable
        data={departments}
        headers={headers}
        rowsPerPage={5}
        defaultOrder="desc"
        defaultOrderBy="riskScore"
        disablePagination
        disableSearchBar
        customFooter={
          <div className="flex justify-end mt-1">
            <ButtonOutlinedGreen onClick={handleClickViewMore} isSmall>
              View all
            </ButtonOutlinedGreen>
          </div>
        }
      />
    </CardWithLoadingState>
  );
}

export default CardDepartmentRiskScores;
