import React, { useEffect, useState } from "react";
import { LoadingSpinner } from "../../../components";
import {
  getTestingCatalogueData,
  patchChangeAssetStatus,
} from "../../../api/database";
import { companyNameMapping } from "../../../utils/helper-functions/company-name-mapping";

import { AssetViewer } from "../../../components/common/dashboard/utils/card-simulation-tracker/utils";
import {
  ConfigureTestingCard,
  ConfigureTestingTable,
  ConfigureNavigation,
  CategoryList,
  SearchInput,
  ConfigureLayout,
} from "../../../components/platform/configure";
import { GridIcon, ListIcon } from "../../../utils";

const TestingDashboard = () => {
  const [trainingData, setTrainingData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: "ascending",
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [resData, setResData] = useState();
  const [loading, setLoading] = useState(true);
  const [showAllAssetTypes, setShowAllAssetTypes] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [contentHTML, setContentHTML] = useState();
  const [cache, setCache] = useState({});
  const [allData, setAllData] = useState([]);
  const [didFetchAllPages, setDidFetchAllPages] = useState(false);

  const toggleSwitch = (index) => {
    const currentStatus = filteredData[index].is_active ? "Inactive" : "Active";
    handleStatusChange(currentStatus, index);
  };

  const sortData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });

    const sortedData = [...filteredData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setFilteredData(sortedData);
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleCloseModal = () => {
    setTitle("");
    setIsOpen(false);
  };

  const handleOpenModal = (content, title) => {
    setTitle(title);
    setContentHTML(content);
    setIsOpen(true);
  };

  const getFormattedCompanyName = (incomingName) => {
    if (!incomingName) return "";
    const mapping = companyNameMapping.find(
      (map) => map.incomingName?.toLowerCase() === incomingName.toLowerCase(),
    );
    return mapping
      ? mapping.formattedName
      : capitalizeFirstLetter(incomingName);
  };

  const defaultFilterOptions = [
    {
      categoryName: "Status",
      categories: [
        { category: "active", categoryDisplayName: "Active" },
        { category: "passive", categoryDisplayName: "Inactive" },
      ],
    },
    {
      categoryName: "Difficulty",
      categories: [
        { category: "easy", categoryDisplayName: "Easy" },
        { category: "medium", categoryDisplayName: "Medium" },
        { category: "hard", categoryDisplayName: "Hard" },
      ],
    },
  ];

  useEffect(() => {
    const fetchConfigureTrainingsData = async () => {
      setLoading(true);

      if (cache[pageNumber]) {
        const cachedData = cache[pageNumber];
        setResData(cachedData.configureTrainingData);
        setTrainingData(cachedData.mappedTrainingData);
        setFilteredData(cachedData.mappedTrainingData);
        setLoading(false);
        return;
      }

      const configureTrainingData = await getTestingCatalogueData(pageNumber);
      const mappedTrainingData = configureTrainingData.processed_assets.map(
        (item) => ({
          ...item,
          impersonation: getFormattedCompanyName(item.impersonation),
        }),
      );

      setResData(configureTrainingData);
      setTrainingData(mappedTrainingData);
      setFilteredData(mappedTrainingData);

      setCache((prevCache) => ({
        ...prevCache,
        [pageNumber]: {
          configureTrainingData,
          mappedTrainingData,
        },
      }));

      console.log("Cache Data", cache);

      // Create unique asset types from company names
      const uniqueAssetTypes = [
        ...new Set(
          configureTrainingData.all_assets.map((item) =>
            getFormattedCompanyName(item.impersonation),
          ),
        ),
      ];

      // Generate categories for the Asset Type
      const assetTypeCategory = {
        categoryName: "Asset Type",
        categories: uniqueAssetTypes.map((company) => ({
          category: company.toLowerCase().replace(/ /g, "_"),
          categoryDisplayName: company,
        })),
      };

      // Set selectedCategories with default and dynamic categories separately
      setSelectedCategories([
        ...defaultFilterOptions.map((option) => ({
          categoryName: option.categoryName,
          isChecked: false,
          subCategories: option.categories.map((sub) => ({
            name: sub.category,
            displayName: sub.categoryDisplayName,
            isChecked: false,
          })),
        })),
        {
          categoryName: assetTypeCategory.categoryName,
          isChecked: false,
          subCategories: assetTypeCategory.categories.map((sub) => ({
            name: sub.category,
            displayName: sub.categoryDisplayName,
            isChecked: false,
          })),
        },
      ]);

      setLoading(false);
    };

    fetchConfigureTrainingsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, cache]);

  useEffect(() => {
    if (trainingData) {
      applyFilters(trainingData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, selectedCategories, trainingData]);

  useEffect(() => {
    if (trainingData) {
      const activeFilters = selectedCategories.reduce((acc, category) => {
        const checkedSubcategories = category.subCategories
          .filter((sub) => sub.isChecked)
          .map((sub) => sub.name);
        return checkedSubcategories.length > 0
          ? { ...acc, [category.categoryName]: checkedSubcategories }
          : acc;
      }, {});

      const filtered = trainingData.filter((item) => {
        const matchesSearch =
          item.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          false;
        const matchesCategory = Object.entries(activeFilters).every(
          ([categoryName, filters]) => {
            if (categoryName === "Status") {
              return filters.includes(item.is_active ? "active" : "passive");
            } else if (categoryName === "Difficulty") {
              return filters.includes(item.fidelity || "");
            } else if (categoryName === "Asset Type") {
              return filters.includes(
                item.impersonation?.toLowerCase().replace(/ /g, "_") || "",
              );
            }
            return true;
          },
        );
        return matchesSearch && matchesCategory;
      });

      setFilteredData(filtered);
    }
  }, [searchQuery, trainingData, selectedCategories]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSubCategoryCheck = (categoryIndex, subCategoryIndex) => {
    setSelectedCategories((prevCategories) => {
      // Create a deep copy of selectedCategories
      const updatedCategories = prevCategories.map((category, idx) => {
        if (idx === categoryIndex) {
          return {
            ...category,
            subCategories: category.subCategories.map((subCategory, subIdx) => {
              if (subIdx === subCategoryIndex) {
                return {
                  ...subCategory,
                  isChecked: !subCategory.isChecked,
                };
              }
              return subCategory;
            }),
          };
        }
        return category;
      });
      return updatedCategories;
    });
  };

  const [activeDiv, setActiveDiv] = useState(2);

  const handleClick = (divNumber) => {
    setActiveDiv(divNumber);
  };

  const handleStatusChange = async (status, index) => {
    const updatedFilteredData = [...filteredData];
    const selectedAsset = updatedFilteredData[index];
    const fileName = selectedAsset.file_name;

    try {
      const requestBody = {
        status: status.toLowerCase(),
        file_name: fileName,
      };

      if (
        (status === "Active" && selectedAsset.is_active) ||
        (status === "Paused" && !selectedAsset.is_active)
      ) {
        return;
      }

      const response = await patchChangeAssetStatus(requestBody);
      console.log("response", response);

      updatedFilteredData[index] = {
        ...selectedAsset,
        is_active: status === "Active",
      };
      setFilteredData(updatedFilteredData);

      const updatedTrainingData = trainingData.map((item) =>
        item.file_name === fileName
          ? { ...item, is_active: status === "Active" }
          : item,
      );
      setTrainingData(updatedTrainingData);

      applyFilters(updatedTrainingData);
    } catch (error) {
      console.error("Failed to update status:", error);
      alert("An error occurred while updating the status.");
    }
  };

  const applyFilters = () => {
    const hasActiveFilters =
      searchQuery.trim() !== "" ||
      selectedCategories.some((category) =>
        category.subCategories.some((sub) => sub.isChecked),
      );

    let dataToFilter = hasActiveFilters ? allData : trainingData;

    const activeFilters = selectedCategories.reduce((acc, category) => {
      const checkedSubcategories = category.subCategories
        .filter((sub) => sub.isChecked)
        .map((sub) => sub.name);
      return checkedSubcategories.length > 0
        ? { ...acc, [category.categoryName]: checkedSubcategories }
        : acc;
    }, {});

    const filteredResults = dataToFilter.filter((item) => {
      const matchesSearch =
        searchQuery === "" ||
        item.title.toLowerCase().includes(searchQuery.toLowerCase());

      const matchesCategory = Object.entries(activeFilters).every(
        ([categoryName, filters]) => {
          if (categoryName === "Status") {
            return filters.includes(item.is_active ? "active" : "passive");
          } else if (categoryName === "Difficulty") {
            return filters.includes(item.fidelity || "");
          } else if (categoryName === "Asset Type") {
            return filters.includes(
              item.impersonation?.toLowerCase().replace(/ /g, "_") || "",
            );
          }
          return true;
        },
      );

      return matchesSearch && matchesCategory;
    });

    setFilteredData(hasActiveFilters ? filteredResults : trainingData);
  };

  useEffect(() => {
    if (trainingData) {
      applyFilters(trainingData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, selectedCategories, trainingData]);

  const moveFirstPage = () => {
    if (resData.page === "1") return;
    setPageNumber(1);
  };

  const movePreviousPage = () => {
    if (resData.has_previous === false) return;
    setPageNumber(resData.previous_page_number);
  };

  const moveNextPage = () => {
    if (resData.has_next === false) return;
    setPageNumber(resData.next_page_number);
  };

  const moveLastPage = () => {
    if (resData.page === resData.num_pages) return;
    setPageNumber(resData.num_pages);
  };

  const expandList = () => {
    setShowAllAssetTypes(!showAllAssetTypes);
  };

  const fetchAllPages = async () => {
    let page = 1;
    let aggregatedData = [];

    try {
      while (true) {
        // Fetch data for the current page
        const response = await getTestingCatalogueData(page);

        // Add the current page's data to aggregatedData
        aggregatedData = [...aggregatedData, ...response.processed_assets];

        // Check if there are more pages to fetch
        if (!response.has_next) break;

        // Move to the next page
        page += 1;
      }

      // Set the full data and mark as fetched
      setAllData(aggregatedData);
      setDidFetchAllPages(true);
      console.log("All data collected:", aggregatedData);
    } catch (error) {
      console.error("Error fetching all pages:", error);
    }
  };

  useEffect(() => {
    if (!didFetchAllPages) {
      setTimeout(() => {
        fetchAllPages();
      }, 10);
    }
  }, [didFetchAllPages]);

  if (!filteredData) {
    return (
      <div className="flex items-center justify-center w-full h-screen text-center">
        <div>
          <div className="flex justify-center">
            <LoadingSpinner />
          </div>
          <div className="text-white">Loading your Phishing Assets...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="text-white">
      {/* <Testing /> */}
      <div className="flex justify-between items-center mt-1.75">
        <div className="flex items-center">
          <div className="mr-0.25">Testing Library</div>
          {/* <Tooltip text="Testing Library helps you to manage all the testing assets for your organization." /> */}
        </div>
      </div>
      <ConfigureLayout
        filters={
          didFetchAllPages ? (
            <CategoryList
              categories={selectedCategories}
              showAllAssetTypes={showAllAssetTypes}
              didFetchAllPages={didFetchAllPages}
              handleSubCategoryCheck={handleSubCategoryCheck}
              expandList={expandList}
            />
          ) : (
            <div className="flex items-center">
              <LoadingSpinner className={`h-1 w-1 mr-0.5`} />
              <div>Loading filters...</div>
            </div>
          )
        }
        data={
          <>
            <div className="flex items-center justify-between">
              <SearchInput
                searchQuery={searchQuery}
                onSearchChange={handleSearchChange}
                didFetchAllPages={didFetchAllPages}
                placeholderTextWhenLoaded="Type in a test's name"
                placeholderTextWhenLoading="Loading all the tests for you"
              />
              <div className="flex mt-0.5 cursor-pointer">
                <div
                  onClick={() => handleClick(1)}
                  className={`rounded-l-small p-0.5 cursor-pointer ${
                    activeDiv === 1
                      ? "border border-green text-black"
                      : "bg-transparent text-white border border-gray border-opacity-35"
                  }`}
                >
                  <ListIcon isActive={activeDiv === 1} />
                </div>
                <div
                  onClick={() => handleClick(2)}
                  className={`rounded-r-small p-0.5 cursor-pointer ${
                    activeDiv === 2
                      ? "border border-green text-black shadow-custom-int"
                      : "bg-transparent text-white border border-gray border-opacity-35"
                  }`}
                >
                  <GridIcon isActive={activeDiv === 2} />
                </div>
              </div>
            </div>
            <div className="mt-1.25">
              <ConfigureNavigation
                page={resData.page}
                totalPages={resData.num_pages}
                loading={loading}
                onMoveFirstPage={moveFirstPage}
                onMovePreviousPage={movePreviousPage}
                onMoveNextPage={moveNextPage}
                onMoveLastPage={moveLastPage}
              />

              <div>
                {activeDiv === 1 && (
                  <div className="mt-1">
                    <ConfigureTestingTable
                      filteredData={filteredData}
                      sortData={sortData}
                      handleOpenModal={handleOpenModal}
                      toggleSwitch={toggleSwitch}
                    />
                  </div>
                )}
              </div>

              {activeDiv === 2 && (
                <div className="grid grid-cols-3 gap-1">
                  {filteredData?.map((card, index) => (
                    <ConfigureTestingCard
                      key={card.title}
                      card={card}
                      index={index}
                      handleOpenModal={handleOpenModal}
                      toggleSwitch={toggleSwitch}
                    />
                  ))}
                </div>
              )}
            </div>
          </>
        }
      />
      <AssetViewer
        isOpen={isOpen}
        onCloseModal={handleCloseModal}
        testHTML={contentHTML}
        title={title}
      />
    </div>
  );
};

export default TestingDashboard;
