import React from "react";

import { CardWithLoadingState, UsersTable } from "../../..";

import { headers } from "../../../../../utils/constants/platform/card-employees-by-department";

function CardEmployeesByDepartment({ users }) {
  return (
    <CardWithLoadingState loadingVariable={users}>
      <UsersTable
        data={users}
        headers={headers}
        defaultOrderBy="name"
        disableSearchBar
      />
    </CardWithLoadingState>
  );
}

export default CardEmployeesByDepartment;
