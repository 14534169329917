import React from "react";

const ERPIcon = () => {
  return (
    <svg
      width="26"
      height="21"
      viewBox="0 0 26 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.1642"
        y="1.02204"
        width="23.7067"
        height="18.7067"
        rx="2.85337"
        stroke="white"
        strokeWidth="0.815249"
      />
      <path
        d="M9.1239 12.9557V13.8754H4.76484V6.99674H9.05683V7.91646H5.81868V9.87085H8.76942V10.7906H5.81868V12.9557H9.1239ZM11.4134 13.8754H10.3596V6.99674H12.9271C14.5079 6.99674 15.4564 7.77275 15.4564 9.14274C15.4564 10.1295 14.8336 10.8864 13.7894 11.1355L15.6575 13.8754H14.3929L12.6781 11.2887H11.4134V13.8754ZM11.4134 7.87813V10.4073H12.8601C13.8373 10.4073 14.3929 9.97623 14.3929 9.14274C14.3929 8.31883 13.8373 7.87813 12.8601 7.87813H11.4134ZM19.0603 11.4803H17.7286V13.8754H16.6748V6.99674H19.0603C20.6027 6.99674 21.5991 7.78233 21.5991 9.21938C21.5991 10.666 20.6027 11.4803 19.0603 11.4803ZM19.0507 7.88772H17.7286V10.5894H19.0507C19.98 10.5894 20.5452 10.0816 20.5452 9.22896C20.5452 8.37631 19.98 7.88772 19.0507 7.88772Z"
        fill="white"
      />
    </svg>
  );
};

export default ERPIcon;
