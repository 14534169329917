import axios from "axios";

import { getToken } from "../utils";

export const postTokenUpload = async (file) => {
  let postTokenUploadResponse = {
    result: {},
    error: {},
  };

  const formData = new FormData();
  formData.append("file", file);
  formData.append("type", "google"); // Adding the type field

  try {
    const token = await getToken();

    const url = `${process.env.REACT_APP_BASE_URL || "https://api.dunesecurity.io"}/integration-token-upload`;
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };

    const response = await axios.post(url, formData, { headers });

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    postTokenUploadResponse.result = response.data;
  } catch (error) {
    postTokenUploadResponse.error = error;
  }

  return postTokenUploadResponse;
};

export default postTokenUpload;
