export const pagesSettings = [
  {
    description: "Set the number of tests per quarter for each department.",
    link: "/configurations/test-frequency/",
    title: "Go to the test frequency page.",
  },
  {
    description: "Exclude specific users from testing in your organization.",
    link: "",
    title: "Go to the apps and services page.",
  },
  {
    description: "Specify the apps and services your organization uses.",
    link: "",
    title: "Go to the exclude users page.",
  },
];
